<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"
                        href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark"
                        href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li>

            </ul>

            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <li class="nav-item"><a routerLink="./analitics"
                        class="nav-link dropdown-toggle waves-effect waves-dark"> <i class="fa fa-bar-chart"
                            aria-hidden="true"></i></a></li>
            <!-- NOTIFICACIONES PUSH -->
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-bell-o {{start()}}" (click)="enf();" aria-hidden="true"></i></a>
                <div class="dropdown-menu dropdown-menu-right animated fadeIn fast">
                    <ul class="dropdown-user" *ngFor="let item of notificacion">
                        <li>
                            <div class="dw-user-box">
                                <div class="u-text">
                                     <h4 [routerLink]="item.location" style="cursor:pointer;"> <i [class]="item.icon" ></i>{{trans(item.type)}}</h4>
                                </div>
                            </div>
                        </li>
                        <li role="separator" class="divider"></li>
                    </ul>
                </div>
            </li>
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"><i class="fa fa-sliders" aria-hidden="true"></i></a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn fast">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">

                                    <div class="u-text">
                                        <h4>{{nombreFinal}}</h4>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a routerLink="account-settings"><i class="ti-settings"></i> Account Setting</a></li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a (click)="logout()" class="pointer">
                                    <i class="fa fa-power-off"></i>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->