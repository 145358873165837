<!-- <img [src]="imgBAseG4" alt=""> -->

<div class="fondoModal" *ngIf="loader">
    <div class="row">
        <div class="col-12 textoLoader">
            <div class="alert text-center h">
                <h4 class="alert-heading b">{{text}}</h4>
                <div *ngIf="status === 0; else checke">
                    <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                    <p class="mb-0">Espere</p>
                </div>
                <ng-template #checke>
                    <i [ngClass]="icono" caria-hidden="true"></i>
                </ng-template>

            </div>
        </div>
    </div>
</div>
<div class="baner">
    <h2 class="d-flex justify-content-center b">CHECADOR</h2>
    <span class="d-flex justify-content-center">
        <i class="fa fa-clock-o fa-3x" aria-hidden="true"></i><br>
    </span>
</div>
<div class="col-md-12 ">
    <div class="d-flex justify-content-center mt-5" *ngIf="!loader">
        <div class="col-md-5">
            <zxing-scanner (scanSuccess)="onCodeResult($event)"></zxing-scanner>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-5" *ngIf="!loader">
        <a href="/login" class="btn btn-danger" >Salir</a>
    </div>
</div>