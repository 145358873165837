import { Component, OnInit } from '@angular/core';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { QrService } from '../../services/qr.service';
import { qr } from '../../interfaces/qr.interfaces';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.css']
})
export class QrComponent implements OnInit {
  loader: boolean = false;
  imgBAseG4: string = '';
  qrResultString: string;
  text: string = 'cargando';
  status: number = 0;
  icono: string = '';
  constructor(private QrService: QrService) { }

  ngOnInit(): void {
    // this.obtenerQr();
  }

  // obtenerQr(): void {
  //   this.QrService.generarQR().subscribe((resp: ResponseGeneral) => {
  //     this.imgBAseG4 = resp.data;
  //   })
  // }
  onCodeResult(resultString: string) {
    this.loader = true;
    const data: qr = JSON.parse(resultString);
    this.QrService.checador(Number(data.id), data.name).subscribe((resp: ResponseGeneral) => {
      this.status = 1;
      if (resp.ok) {
        this.icono = 'fa fa-check fa-5x text-success'
        this.text = resp.msg;

      } else {
        this.icono = 'fa fa-times fa-5x text-danger';
        this.text = resp.msg;
      }
      setTimeout(() => {
        this.loader = false;
        this.status = 0;
        this.text = 'Cargando'
      }, 3000);
    })
  }
}
