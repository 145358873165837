<section id="wrapper">
    <div class="col-md-12">
        <div class="d-flex justify-content-center">
            <div class="login-box card">
                <div class="card-body">
                    <div class="d-flex justify-content-center mt-5">
                        <img src="./assets/images/background/login-register.jpg" width="300">
                    </div>
                    <form class="form-horizontal form-material" id="loginform" [formGroup]="loginForm" (ngSubmit)="submitLogin();" autocomplete="off">
                        <div class="d-flex justify-content-center">Inicio de Sesión</div>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input class="form-control" type="email" formControlName="correo" placeholder="Email"> </div>
                        </div>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" formControlName="password" placeholder="Password"> </div>
                        </div>
                        
                        <div class="form-group text-center">
                            <div class="col-xs-12 p-b-20">
                                <button class="btn btn-block btn-lg btn-info btn-rounded" [disabled]="!loginForm.valid" type="submit">Entrar</button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="cargando">
                            <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                        </div>
                        <!-- <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                <a routerLink="/register" class="text-info m-l-5"><b>Registrar</b></a>
                            </div>
                            <div class="col-sm-12 text-center">
                                <a routerLink="/checador" class="text-info m-l-5" target="_blanck"><b>Checador</b></a>
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>