import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { UsuariosService } from '../../services/usuarios.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [
  ]
})
export class SidebarComponent implements OnInit {

  menuItems: any[];
  urlImg: string = '';
  nombre: string = '';
  role: string = '';
  subscription: Subscription

  constructor(private sidebarService: SidebarService, UsuariosService: UsuariosService) {
    this.menuItems = sidebarService.obtenerMenu()
    this.urlImg = UsuariosService.imgUrlFinal(sessionStorage.getItem('img'));
    this.nombre = UsuariosService.nombreFinal(sessionStorage.getItem('nombre'));
    this.subscription = UsuariosService.change$.subscribe(() => {
    this.urlImg = UsuariosService.imgUrlFinal(sessionStorage.getItem('img'));
    this.role = sessionStorage.getItem('role');

    });
  }

  ngOnInit(): void {
  }

}
