import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[ErrorMsg]'
})
export class ErrorMsgDirective {

  private _mensaje: string = '';
  htmlElement: ElementRef<HTMLElement>

  @Input() set mensaje(valor: string) {
    this._mensaje = valor;
    this.setMensaje();
  }
  @Input() set valido(valor: boolean) {
    if(valor){
      this.htmlElement.nativeElement.classList.add('hidden');
    } else {
      this.htmlElement.nativeElement.classList.remove('hidden');
    }
  }
  constructor(private el: ElementRef<HTMLElement>) {
    this.htmlElement = el; 
  }
  setMensaje():void {
    this.htmlElement.nativeElement.innerHTML = this._mensaje;
  }

}
