import { Component, OnInit } from '@angular/core';
import { Clientes, Usuarios } from 'src/app/interfaces/usuario.interfaces';
import { Acciones } from 'src/app/module/acciones.module';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { UsuariosService } from '../../services/usuarios.service';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {
  accionPagina = Acciones.Listar;
  siguientePaginacion: boolean = true;
  anteriorPaginacion: boolean = true;
  loader: boolean = false;
  NumTotal: number = 0;
  listaUsuarios: Clientes[] = [];
  page = 1;

  userForm = this.fb.group({
    name: [null, [Validators.required, Validators.minLength(1)]],
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(8)]],
    role: [null, [Validators.required]],
    uid: [null]
  })
  mensajeError: string;
  constructor(private fb: FormBuilder, private UsuariosService: UsuariosService) { }

  ngOnInit(): void {
    localStorage.setItem('numUsuarios', '10')
    this.obtenerUsuarios();
  }

  obtenerUsuarios(numUsuarios = localStorage.getItem('numUsuarios')): void {
    this.UsuariosService.obtenerListaClientes(Number(numUsuarios)).subscribe((resp: ResponseGeneral) => {
      this.NumTotal = resp.numTotal;
      this.listaUsuarios = resp.data;
      this.listaUsuarios.map((s: Usuarios) => {
        if (s.role === 1) {
          s.role = 'Administrador'
        } else {
          s.role = 'Asesor'
        }
        return s;
      })
      this.siguientePaginacion = resp.siguiente;
      this.anteriorPaginacion = resp.anterior;
      this.loader = true
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    });
  }

  nuevoUsuario(): void {
    this.accionPagina = Acciones.Crear;
    this.userForm.reset();
  }
  actualizarUsuario(data: any): void {
    this.userForm.get('uid')?.setValue(data.id);
    this.userForm.get('name')?.setValue(data.nombre);
    this.userForm.get('email')?.setValue(data.correo);
    this.userForm.get('password')?.setValue(data.password);
    if (data.role === 'Administrador') {
      data.role = 0;
    } else {
      data.role = 1;
    }
    this.userForm.get('role')?.setValue(data.role);
    this.accionPagina = Acciones.Actualizar;
  }
  deleteCliente(data: any): void {
    Swal.fire({
      icon: 'warning',
      title: `Seguro se eliminara el ususario ${data.nombre}`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = false;
        this.UsuariosService.DeleteUsuario(data.id).subscribe((resp: ResponseGeneral) => {
          if (resp.ok) {
            this.loader = true;
            this.obtenerUsuarios();
            Swal.fire('Correcto', 'Usuario eliminado', 'success');
            this.accionPagina = Acciones.Listar;
          }
        },
          (err) => {
            Swal.fire('Error', err.error.msg, 'error')
          })
      }
    })
  }
  siguiente() {
    this.loader = false;
    let numUsuario = Number(localStorage.getItem('numUsuarios'));
    numUsuario = numUsuario + 10

    this.obtenerUsuarios(numUsuario.toString());
  }
  anterior() {
    this.loader = false;
    let numUsuario = Number(localStorage.getItem('numUsuarios'));
    numUsuario = numUsuario - 10;
    this.obtenerUsuarios(numUsuario.toString());
  }
  submitUsuario(): void {
    switch (this.accionPagina) {
      case Acciones.Actualizar:
        this.modificarUsuario();
        break;

      default:
        this.crearUsuario();
        break;
    }
  }
  modificarUsuario(): void {
    this.loader = false;
    this.UsuariosService.ActualizarUsuario(this.userForm.value).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.loader = true;
        this.obtenerUsuarios();
        Swal.fire('Correcto', 'Usuario actualizado Correctamente', 'success');
        this.accionPagina = Acciones.Listar;
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }
  crearUsuario(): void {
    this.UsuariosService.CrearUsuario(this.userForm.value).subscribe((resp: ResponseGeneral) => {
      this.loader = true;
      if (resp.ok) {
        this.loader = false;
        this.obtenerUsuarios();
        Swal.fire('Correcto', 'Usuario Agregado Correctamente', 'success');
        this.accionPagina = Acciones.Listar;
      }
    },
      (err) => {
        Swal.fire('Error', err.error.msg, 'error');
      })
  }
  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.userForm.get(campo)?.errors) {
      if (campo === 'password') {
        this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos 8 caracteres`
        return !ok;
      }
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }
  cancelar(): void {
    this.loader = false;
    this.userForm.reset();
    this.accionPagina = Acciones.Listar;
    this.obtenerUsuarios();
  }
}
