import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { InstructoresService } from '../../services/instructores.service';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { instructoresModel } from '../../interfaces/instructores.interfaces';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-modales',
  templateUrl: './modales.component.html',
  styleUrls: ['./modales.component.css']
})
export class ModalesComponent implements OnInit {

  // @Input('valor') switch: boolean = false;
  @Output() valorSalida : EventEmitter<boolean> = new EventEmitter();


  public listaInstructores: instructoresModel[] = [];
  valor: number = 0;
  numCliente: string;
  constructor(public ModalService: ModalService, private InstructoresService: InstructoresService, private UsuariosService:UsuariosService) {
    this.numCliente = this.UsuariosService.idUsuario
   }
  ngOnInit(): void {
    this.obtenerInstructores();
  }

  get ValorInstructor(){
    return this.ModalService.idIstructor;
  }

  obtenerInstructores(): void {
    this.InstructoresService.ontenerInstructores(Number(this.numCliente)).subscribe((resp: ResponseGeneral) => {
      const listaInstructores = resp.data;
      this.listaInstructores = [...listaInstructores];
    });
  }
  cerrarModal(): void {
    this.ModalService.cerrarModal();
    if(this.ValorInstructor === 0){
      this.valor = 0;
      this.ModalService.setIdInstructor(this.valor);
     return this.valorSalida.emit(false);
    }
  }
  aceptar(): void {
    this.ModalService.setIdInstructor(Number(this.valor));
    this.cerrarModal();
  }



}
