import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { SetingsImgService } from '../../services/setings-img.service';
import { ResponseGeneral, responsesAdmin } from '../../interfaces/response.interfaces';
import { FormBuilder, Validators } from '@angular/forms';
import { Acciones } from '../../module/acciones.module';
import { UsuariosService } from '../../services/usuarios.service';

@Component({
  selector: 'app-setings-img',
  templateUrl: './setings-img.component.html',
  styleUrls: ['./setings-img.component.css']
})
export class SetingsImgComponent implements OnInit {
  mensajeError: string;
  public imagenSubir: File;
  public imgTemp: any = null;
  private _idNegocio:number = 0;

  get getLocalStatus() {
    return localStorage.getItem('openConfig');
  }

  accionSubmi: Acciones = Acciones.Crear;



  congForm = this.fb.group({
    nombre: [null, [Validators.required]],
    direccion: [null, [Validators.required]],
    user_id: [null],
  });

  constructor(public ModalService: ModalService, private SetingsImgService: SetingsImgService, private fb: FormBuilder, private UsuariosService: UsuariosService) { }

  @Output() valorSalida: EventEmitter<boolean> = new EventEmitter();
  ngOnInit(): void {
  }




  submitConfig(): void {
    this.crearConfig();
  }

  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.congForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }

  cambiarImagen(file: File) {
    this.imagenSubir = file;
    if (!file) {
      return this.imgTemp = null;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.imgTemp = reader.result;
    }
  }

  async crearConfig(): Promise<void> {
    if (!this.congForm.valid) {
      return
    }
    this.congForm.get('user_id')?.setValue(sessionStorage.getItem('id'));
    this.SetingsImgService.crearConfig(this.congForm.value).then((resp: ResponseGeneral) => {
      if(resp.ok){
        this._idNegocio = Number(resp.uid);
        if(this.imgTemp !== null){
          this.subirImagen();
        } else {
          this.ModalService.cerrarModalSetingIMG();
        }
      }
    }, (erro) => {
      console.log(erro);
    })
    // this.SetingsImgService.crearConfig(this.congForm.value).subscribe((resp: ResponseGeneral) => {
    //   if (resp.ok) {
    //     if(this.imgTemp !== null){
    //       this.subirImagen();
    //     }
    //   }
    // })
  }
  async subirImagen(): Promise<void> {
    this.SetingsImgService.actualizarImagen(this.imagenSubir, "negocio", this._idNegocio).then((resp: ResponseGeneral) => {
      if(resp.ok){
        this.ModalService.cerrarModalSetingIMG();
      }
    })
  }
}
