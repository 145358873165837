import { Component, OnInit } from '@angular/core';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { SetingsImgService } from 'src/app/services/setings-img.service';
import { SettingsService } from '../../services/settings.service';
import { UsuariosService } from '../../services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styles: [
  ]
})
export class AccountSettingsComponent implements OnInit {
  imagenSubir: File;
  imgTemps: any = null;
  habilitar: boolean = true;
  private _idCliente: number;

  constructor(private settingsService: SettingsService, private SetingsImgService: SetingsImgService, private UsuariosService: UsuariosService) {
    this.imgTemps = this.UsuariosService.imgUrlFinal(sessionStorage.getItem('img'));
  }

  ngOnInit(): void {
    this.settingsService.checkCurrentTheme();
  }

  changeTheme(theme: string) {
    this.settingsService.changeTheme(theme);
  }

  cambiarImagen(file: File) {
    this.imagenSubir = file;
    if (!file) {
      this.habilitar = true;
      return this.imgTemps = null;
    }
    let type = file.type.split('/');
    const types = this.settingsService.formatoValido(type[1]);

    if(!types){
      Swal.fire('Error', 'Formato no valido', 'error');
      return
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.imgTemps = reader.result;
    }
    this.habilitar = false;
  }
  async subirImagen(): Promise<void> {
    this._idCliente = Number(this.UsuariosService.idUsuari);
    this.SetingsImgService.actualizarImagen(this.imagenSubir, "clientes", this._idCliente).then((resp: ResponseGeneral) => {
      this.imgTemps = this.UsuariosService.imgUrlFinal(sessionStorage.getItem('img'));
      this.habilitar = false

      Swal.fire('Correcto', 'Imagen acualizada correctamente', 'success');
    }, (err) => {
      Swal.fire('Error', err.error, 'error');
    })
  }


}
