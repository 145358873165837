<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="alert  text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="accionPagina === 'Listar' && loader">
    <div class="col-12">
        <div class="card">
            <div class="d-flex justify-content-end mr-2 mt-2">
                <button type="button" class="btn btn-circle btn-md btn-primary" data-placement="top" (click)="crearRespuesta()"
                    title="Agregar nuevo instructor"><i class="fa fa-plus"></i></button>
            </div>
            <div class="card-body">
                <h4 class="card-title">Respuesras Rapidas ( {{Listadorespuestas.length}} )</h4>
                <h6 class="card-subtitle">Respuestas registradas</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Respuesta</th>
                                <th>Fecha Creación</th>
                                <th>Fecha Modificación</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lista of Listadorespuestas">
                                <td>{{lista.nombre}}</td>
                                <td>{{lista.created}}</td>
                                <td>{{lista.modified}}</td>
                                <td class="text-nowrap">
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Edit" (click)="modificarRespuesta(lista)"
                                        > <i
                                            class="fa fa-pencil text-inverse m-r-10"></i> </a>
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Borrar"> <i
                                            class="fa fa-close text-danger" (click)="elimiarRespuesta(lista)"></i> </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="accionPagina !== 'Listar' && loader">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3>{{accionPagina === 'Crear' ? 'Crear nueva respuesta': 'Editar respuesta'}}</h3>
                <div class="card-body">
                    <form class="form-horizontal form-material" [formGroup]="respuestasForm" (submit)="submitRespuesta()">
                        <div class="form-group">
                            <label>Respuesta rapida</label>
                            <input type="text" class="form-control" formControlName="nombre">
                        </div>
                        <span ErrorMsg [valido]="!campoNoValido('nombre')" class="text-danger"
                        [mensaje]="mensajeError"></span>
                        <div class="row">
                            <button class="btn btn-secondary mr-2" [disabled]="!respuestasForm.valid"> Guardar </button>
                            <button class="btn btn-danger" (click)="cancelarRespuesta();"> Cancelar </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

