import { EventEmitter, Injectable, Output } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SoketioService {
  @Output() outEven: EventEmitter<any> = new EventEmitter();

  socket: Socket;
  constructor() { }
  socketConect() {
    this.socket = io({ transports: ['websocket', 'polling'] })
  }
  socketNotificacion() {
    this.socket.on('new-noty', (data: Object) => {
      this.outEven.emit(data);
    })
  }
}
