import { Component, OnInit } from '@angular/core';
import { Acciones } from '../../module/acciones.module';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuariosService } from '../../services/usuarios.service';
import { responsesAdmin } from 'src/app/interfaces/response.interfaces';
import Swal from 'sweetalert2';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { Clientes } from 'src/app/interfaces/usuario.interfaces';
import { ModalService } from '../../services/modal.service';


@Component({
  selector: 'app-register-cliente',
  templateUrl: './register-cliente.component.html',
  styleUrls: ['./register-cliente.component.css']
})
export class RegisterClienteComponent implements OnInit {

  accionPagina = Acciones.Listar;
  listaClientes: Clientes[] = [];
  loader: boolean = false;
  checkeds: boolean = false;
  cancelarModalSwitch: boolean = true;
  siguientePaginacion: boolean = true;
  anteriorPaginacion:boolean = true;

  userForm = this.fb.group({
    nombre: ['Ricardo Leonel garcia Hernandez', [Validators.required]],
    correo: ['abs@gmmail.com', [Validators.required, Validators.email]],
    direccion: ['tulipanes', [Validators.required]],
    edad: [12, [Validators.required, Validators.minLength(1)]],
    telefono: [12345678, [Validators.required]],
    dni: ['uduewnduen', [Validators.required]],
    checkeds: [],
    checkedsI: [],
    status: [],
    id: [],
  })
  mensajeError: string = '';
  constructor(private fb: FormBuilder, private UsuariosService: UsuariosService, private ModalService: ModalService) { }

  ngOnInit(): void {
    localStorage.setItem('numCliente', '10')
    this.obtenerClientes();
  }

  obtenerClientes(numCliuentes = localStorage.getItem('numCliente')): void {
    this.UsuariosService.obtenerListaClientes(Number(numCliuentes)).subscribe((resp: ResponseGeneral) => {
      this.listaClientes = resp.data;
      this.siguientePaginacion = resp.siguiente;
      this.anteriorPaginacion = resp.anterior;
      this.loader = true

    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    });
  }

  nuevoCliente(): void {
    this.accionPagina = Acciones.Crear;
    this.userForm.reset();
  }
  siguiente() {
    this.loader = false;
    let numClentes = Number(localStorage.getItem('numCliente'));
    numClentes = numClentes + 10
    this.obtenerClientes(numClentes.toString());
  }
  anterior() {
    this.loader = false;
    let numCLiente = Number(localStorage.getItem('numCliente'));
    numCLiente = numCLiente - 10;
    this.obtenerClientes(numCLiente.toString());
  }

  submitUsuario(): void {
    switch (this.accionPagina) {
      case Acciones.Actualizar:
        this.accionModificarCliente();
        break;

      default:
        this.crearUser();
        break;
    }
  }

  inscribir(): void {
    const check = this.userForm.get('checkedsI')?.value;
    if (check) {
      this.ModalService.abirModal();
    }
  }

  inscribirmensualidad(): void {
    const check = this.userForm.get('checkeds')?.value;
    if (check) {
      this.ModalService.abirModalMensualidad();
    }
  }

  deleteCliente(data: Clientes): void {
    Swal.fire({
      title: `Estas Seguro ?`,
      text: `Se eliminara al cliente ${data.nombre}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.UsuariosService.deleteCliente(data).subscribe((req: ResponseGeneral) => {
          Swal.fire('Correcto', req.msg, 'success');
          this.obtenerClientes();
        }, (err) => {
          Swal.fire('Error', err, 'error')
        })
      }
    })
  }

  actualizarUsuario(data: Clientes): void {
    this.userForm.controls.nombre.setValue(data.nombre);
    this.userForm.controls.direccion.setValue(data.direccion);
    this.userForm.controls.correo.setValue(data.correo);
    this.userForm.controls.telefono.setValue(data.telefono);
    this.userForm.controls.edad.setValue(data.edad);
    this.userForm.controls.dni.setValue(data.DNI);
    this.userForm.controls.id.setValue(data.id);
    this.accionPagina = Acciones.Actualizar
  }

  accionModificarCliente() {
    this.UsuariosService.actualizarCliente(this.userForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerClientes();
      this.accionPagina = Acciones.Listar;
      Swal.fire('Correcto', 'Cliente Actualizado', 'success');
    }, (err) => {
      return Swal.fire('Error', err, 'error');
    })
  }
  crearUser(): void {
    if (!this.userForm.valid) {
      return;
    }
    const instructor = this.ModalService.idIstructor;
    const mensualidad = this.ModalService.idMensualidad;
    this.UsuariosService.crearUsuario(this.userForm.value, Number(instructor), Number(mensualidad)).subscribe((resp: responsesAdmin) => {
      if (resp.ok) {
        this.obtenerClientes();
        this.accionPagina = Acciones.Listar
        Swal.fire('Correcto', 'Cliente agregado correctamente', 'success');
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    });
  }
  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.userForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }

  cancelarCliente(): void {
    this.loader = true
    this.obtenerClientes();
    this.accionPagina = Acciones.Listar;
  }

  cambioValorHijo(valor: boolean) {
    this.userForm.get('checkedsI')?.setValue(valor);
  }
  cambioValorMensualidad(valor: boolean) {
    this.userForm.get('checkeds')?.setValue(valor)
  }
}
