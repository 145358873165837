import { Component, OnInit } from '@angular/core';
import { InscripcionService } from '../../services/inscripcion.service';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { Acciones } from '../../module/acciones.module';
import { MensualidadesService } from '../../services/mensualidades.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-inscripciones-avencer',
  templateUrl: './inscripciones-avencer.component.html',
  styleUrls: ['./inscripciones-avencer.component.css']
})
export class InscripcionesAvencerComponent implements OnInit {
  listadoInscripciones = [];
  ListaMensualidades = [];
  loader: boolean = false;
  accionPagina = Acciones.Listar;
  mesesNumero:number = 1;
  tipo:string = 'todos'

  formInscripAvencer = this.fb.group({
    select: [this.mesesNumero],
    tipo: [this.tipo],
  })

  constructor(private InscripcionService: InscripcionService, private MensualidadesService: MensualidadesService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.obtenerListadoDeinscripcionesAvencer(this.mesesNumero, this.tipo);
  }

  obtenerListadoDeinscripcionesAvencer(meses: number, tipo: string): void {
    console.log(tipo);
    
    this.InscripcionService.inscripcionesAvencer(meses, tipo).subscribe((resp: ResponseGeneral) => {
      const ListadoResult = resp.data;
      this.listadoInscripciones = [...ListadoResult];

    });
    this.MensualidadesService.obtenerListadoMensualidades().subscribe((resp: ResponseGeneral) => {
      const ListaMensualidades = resp.data;

      ListaMensualidades.map((str: any) => {
        let nombre = str.nombre;
        str.nombre = `${nombre} - ${str.meses} ( MESES )`
        return str
      });
      this.ListaMensualidades = [...ListaMensualidades];
      this.loader = true;
    })
  }

  search(): void {
    this.loader = false;
    this.mesesNumero = Number(this.formInscripAvencer.get('select')?.value);
    this.tipo = this.formInscripAvencer.get('tipo')?.value;
    this.obtenerListadoDeinscripcionesAvencer(this.mesesNumero, this.tipo);
  }

}
