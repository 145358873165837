<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="alert  text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="accionPagina === 'Listar' && loader">
    <div class="col-12">

        <div class="card">
            <div class="d-flex justify-content-end mr-2 mt-2">
                <button type="button" class="btn btn-circle btn-md btn-primary" data-placement="top"
                    title="Agregar nuevo instructor" (click)="nuevoInstructor();"><i class="fa fa-plus"></i></button>
            </div>
            <div class="card-body">
                <h4 class="card-title">Instructores ( {{listaInstructores.length}} )</h4>
                <h6 class="card-subtitle">Instructores registrados</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>correo</th>
                                <th>direccion</th>
                                <th>DNI</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lista of listaInstructores">
                                <td>{{lista.nombre}}</td>
                                <td>{{lista.correo}}</td>
                                <td>{{lista.direccion}}</td>
                                <td>{{lista.DNI}}</td>
                                <td class="text-nowrap">
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Edit"
                                        (click)="actualizarInstructor(lista)"> <i
                                            class="fa fa-pencil text-inverse m-r-10"></i> </a>
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Borrar"> <i
                                            class="fa fa-close text-danger" (click)="deleteInstructor(lista)"></i> </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-primary mr-2" (click)="anterior()" [disabled]="!anteriorPaginacion"><i class="fa fa-arrow-left" aria-hidden="true"></i> anterior</button>
                    <button class="btn btn-primary" (click)="siguiente()" [disabled]="!siguientePaginacion"> <i class="fa fa-arrow-right" aria-hidden="true"></i> siguiente</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="accionPagina !== 'Listar'">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3>{{accionPagina === "Crear" ? 'Crear nuevo Instructor':'Editar Instructor'}}</h3>
            </div>
            <div class="card-body">

                <form class="form-horizontal form-material" [formGroup]="instructorForm" (submit)="(submitInstructor())">
                    <div class="form-group">
                        <label>Nombre completo</label>
                        <input type="text" class="form-control" formControlName="nombre">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('nombre')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Correo</label>
                        <input type="email" class="form-control" formControlName="correo">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('correo')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Direccion</label>
                        <input type="text" class="form-control" formControlName="direccion">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('direccion')" class="text-danger"
                        [mensaje]="mensajeError"></span>
                    <div class="form-group">
                        <label>Edad</label>
                        <input type="text" class="form-control" formControlName="edad">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('edad')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Teléfono</label>
                        <input type="text" class="form-control" formControlName="telefono">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('telefono')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>DNI</label>
                        <input type="text" class="form-control" formControlName="dni">
                    </div>

                    <span ErrorMsg [valido]="!campoNoValido('dni')" class="text-danger" [mensaje]="mensajeError"></span>

                    <div class="row">
                        <button class="btn btn-secondary mr-2" [disabled]="!instructorForm.valid"> Guardar </button>
                        <button class="btn btn-danger" (click)="cancelarInstructor();"> Cancelar </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>