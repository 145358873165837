import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { respuestas } from '../interfaces/respuestas.interfaces';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class RespuestasService {

  constructor(private http: HttpClient) { }

  ObtenerListadoRespuestas() {
    const url = `${base_url}/respuestas/`
    return this.http.get(url);
  }

  crearRespuesta(data: respuestas) {
    const url = `${base_url}/respuestas/new`;
    return this.http.post(url, data);
  }
  eliminarRespuesta(id: number) {
    const url = `${base_url}/respuestas/dele/${id}`;
    return this.http.delete(url);
  }
  actualizarRespuesta(data: respuestas) {
    const url = `${base_url}/respuestas/edit`;
    return this.http.put(url, data);
  }

}
