<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div class="col-md-12" *ngIf="loader">
    <div class="d-flex justify-content-center">
        <mat-form-field class="example-form-field" appearance="fill" >
            <mat-label>Rango de Fechas</mat-label>
            <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker" 
                [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end">
                <input matStartDate placeholder="Inicio" formControlName="start" disabled>
                <input matEndDate placeholder="Fin" formControlName="end" disabled (dateChange)="cargaXFecha()">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker disabled="false"></mat-date-range-picker>
        </mat-form-field>
    </div>

    <label>Sucursales</label>
    <select class="form-control mb-5" [(ngModel)]="valor" (change)="oneAnalitics()">
        <option *ngFor="let item of location" [ngValue]="item.name">{{item.title}}</option>
    </select>

    

    <div class="col-md-12">
        <div class="row">
            <div class="col md-6">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            <h3>Reseñas</h3>
                        </div>
                        <div class="row">
                            <i class="fa fa-times-circle text-danger fa-2x mr-3" aria-hidden="true"></i> SIN CONTESTAR(
                            {{sinContestar}} )
                        </div>
                        <div class="row">
                            <i class="fa fa-check-circle text-success fa-2x mr-3" aria-hidden="true"></i> CONTESTADAS (
                            {{contestadas}} )
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <h3>Calificación de Reseñas</h3>
                        <div class="progress m-t-20 bps">
                            <label class="flable">(5)</label> <i class="fa fa-star fa-2x" aria-hidden="true"></i>
                            <div class="progress-bar bg-primary progress-bar-striped active mt-1" style="height:15px;"
                                [style.width]="fives" role="progressbar">
                            </div>
                            <label class="flable ml-2">{{fives }}</label>
                        </div>
                        <div class="progress m-t-20 bps">
                            <label class="flable">(4)</label> <i class="fa fa-star fa-2x" aria-hidden="true"></i>
                            <div class="progress-bar bg-primary progress-bar-striped active mt-1 " style="height:15px;"
                                [style.width]="fords" role="progressbar">
                            </div>
                            <label class="flable ml-2">{{fords}}</label>
                        </div>
                        <div class="progress m-t-20 bps">
                            <label class="flable">(3)</label> <i class="fa fa-star fa-2x" aria-hidden="true"></i>
                            <div class="progress-bar bg-primary progress-bar-striped active mt-1" style="height:15px;"
                                [style.width]="trees" role="progressbar">
                            </div>
                            <label class="flable ml-2">{{trees}}</label>
                        </div>
                        <div class="progress m-t-20 bps">
                            <label class="flable">(2)</label> <i class="fa fa-star fa-2x" aria-hidden="true"></i>
                            <div class="progress-bar bg-primary progress-bar-striped active mt-1" style="height:15px;"
                                [style.width]="twos" role="progressbar">
                            </div>
                            <label class="flable ml-2">{{twos}}</label>
                        </div>
                        <div class="progress m-t-20 bps">
                            <label class="flable">(1)</label> <i class="fa fa-star fa-2x" aria-hidden="true"></i>
                            <div class="progress-bar bg-primary progress-bar-striped active mt-1" style="height:15px;"
                                [style.width]="ones" role="progressbar">
                            </div>
                            <label class="flable ml-2">{{ones}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="col-lg-6">
            <div class="card targ ">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <span class="color"><i class="fa fa-mobile fa-3x mr-2" aria-hidden="true"></i> <span
                                    class="mb-5 color">LLAMADAS TELEFÓNICAS ( {{dataAnalitycs.ACTIONS_PHONE}}
                                    )</span></span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div style="display: block">
                    <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType">
                    </canvas>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-10">
        <div class="col-lg-6">
            <div class="card targ ">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <span class="color"><i class="fa fa-desktop fa-3x mr-2" aria-hidden="true"></i> <span
                                    class="mb-5 color">ACCIONES DE SITIO ( {{dataAnalitycs.ACTIONS_WEBSITE}}
                                    )</span></span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div style="display: block">
                    <canvas baseChart [data]="pieChartData" [options]="pieChartOptions" [type]="pieChartType">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-10">
        <div class="col-lg-6">
            <div class="card targ">
                <div class="card-body ">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <span class="color"><i class="fa fa-search fa-3x mr-2" aria-hidden="true"></i> <span
                                    class="mb-5 color">BUSQUEDAS ( {{dataAnalitycs.VIEWS_SEARCH}}
                                    )</span></span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div style="display: block">
                    <canvas baseChart [data]="piesChartData" [options]="piesChartOptions" [type]="barChartType">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-10">
        <div class="col-lg-6">
            <div class="card targ">
                <div class="card-body ">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <span class="color"><i class="fa fa-sign-out fa-3x mr-2 " aria-hidden="true"></i><span
                                    class="mb-5 color">INDIRECTAS ( {{dataAnalitycs.QUERIES_INDIRECT}}
                                    )</span></span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div style="display: block">
                    <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [type]="lineChartType">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-10">
        <div class="col-lg-6">
            <div class="card targ">
                <div class="card-body ">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <span class="color"><i class="fa fa-sign-in fa-3x mr-2" aria-hidden="true"></i><span
                                    class="mb-5 color">DIRECTAS ( {{dataAnalitycs.QUERIES_DIRECT}}
                                    )</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div style="display: block">
                    <canvas baseChart [data]="lineChartData2" [options]="lineChartOptions" [type]="lineChartType2">
                    </canvas>
                </div>
            </div>
        </div>
    </div>


</div>