import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class QrService {

  constructor(private http: HttpClient) { }

  generarQR(id_usuario = 17){
    const url = `${base_url}/qr/createdQR/${id_usuario}`;
    return this.http.get(url);
  }
  checador(id_usuario:number, nombre:string){
    const url = `${base_url}/qr/checador`;
    const data = {
      id_usuario: id_usuario,
      nombre: nombre
    }
    return this.http.post(url,data);
  }
}
