import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Inscripcion } from '../interfaces/inscripcion.interfaces';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class InscripcionService {

  constructor(private http: HttpClient) { }

  obtenerListadoInscripciones() {
    const url = `${base_url}/inscripcion/`;
    return this.http.get(url)
  }

  crearInscripcion(data:Inscripcion){
    const url = `${base_url}/inscripcion/new`;
    return this.http.post(url, data);
  }

  actualizarInscripcion(data:Inscripcion){
    const url = `${base_url}/inscripcion/edit`;
    return this.http.put(url,data);
  }

  eliminarInscripcion(idInscripcion:number){
    const url = `${base_url}/inscripcion/delete/${idInscripcion}`;
    return this.http.delete(url);
  }
  inscripcionesAvencer(meses:number,tipo:string){
    const url = `${base_url}/inscripcion/iav/${meses}/${tipo}`;
    return this.http.get(url);
  }

  editarInscripcion(data:any){
    const url = `${base_url}/inscripcion/editInscripcion`;
    return this.http.put(url,data);
  }
}
