<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="alert  text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="loader">

    <div class="card" *ngIf="buscador">
        <div class="card-body">
            <label>Sucursales</label>
            <select class="form-control" (change)="getlistadoPublicaciones()" [(ngModel)]="locationsSelect">
                <option disabled selected>-- SELECCIONE --</option>
                <option *ngFor="let item of location" [value]="item.name">{{item.title}}</option>
            </select>
        </div>
    </div>

    <div class="card" *ngIf="accionPagina === 'Listar' && loader">
        <div class="card-body">
            <div class="d-flex justify-content-end m-2">
                <button type="button" class="btn btn-circle btn-md btn-primary" data-placement="top"
                    title="Agregar nuevo cliente" (click)="crearPublicacion();"><i class="fa fa-plus"></i></button>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Titutlo</th>
                            <th>Tipo</th>
                            <th class="text-nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let lista of listadoPublicaciones | paginate: {itemsPerPage: 10, currentPage: page}">
                            <td><img [src]="lista.img" width="150px"></td>
                            <td>{{lista.titulo}}<br>{{lista.summary}}</td>
                            <td>{{lista.type}}</td>
                            <td class="text-nowrap">
                                <i class="fa fa-trash-o fa-2x" (click)="deletePublicacion(lista.id)" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="page = $event" nextLabel="Siguiente" previousLabel="Anterior">
                </pagination-controls>
            </div>
        </div>
    </div>
    <div *ngIf="accionPagina === 'Crear' && loader">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="p-2">
                        <h4 class="m-2">Nueva Publicación para ( {{NombreLocation}} )</h4>
                    </div>
                    <div class="ml-auto p-2"><i class="mdi mdi-close-circle-outline pointer"
                            (click)="regresarListado()"></i></div>
                </div>
                <mat-stepper [linear]="true" #stepper>
                    <form [formGroup]="publicidadForm">
                        <mat-step [stepControl]="publicidadForm">
                            <ng-template matStepLabel>Datos Publicación</ng-template>
                            <div>
                                <label class="mt-2">Tipo de publicacion</label>
                                <select class="form-control" (change)="contenidoPublicacionMethod();"
                                    formControlName="topicType">
                                    <option value="0" disabled selected>-- SELECCIONE --</option>
                                    <option value="OFFER">OFERTA</option>
                                    <option value="STANDARD">ESTANDAR</option>
                                </select>
                            </div>
                            <div>
                                <div class="mt-3">
                                    <label>ACCIÓN DE PUBLICACIÓN</label>
                                    <select class="form-control" formControlName="callToAction">
                                        <option value="0" disabled selected>-- SELECCIONE --</option>
                                        <option value="SIGN_UP">INSCRIPCIÓN</option>
                                        <option value="ORDER">ORDEN</option>
                                        <option value="BOOK">CITA</option>
                                        <option value="SHOP">SHOP</option>
                                        <option value="LEARN_MORE">INFORMACIÓN</option>
                                        <option value="CALL">LLAMADA</option>
                                    </select>
                                </div>
                                <label class="mt-3">Url redireccionamiento</label>
                                <input formControlName="urlAction" class="form-control">

                                <div class="row" *ngIf="oferta">
                                    <div class="col-md-6">
                                        <h4 class="mt-3">Fecha y Hora Inicio </h4>
                                        <mat-form-field>
                                            <input matInput type="datetime-local" placeholder="start date"
                                                formControlName="fstart">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <h4 class="mt-3">Fecha y Hora Fin </h4>
                                        <mat-form-field>
                                            <input matInput type="datetime-local" placeholder="start date" (change)="validacionForm()"
                                                formControlName="fend">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="mt-3" mat-button matStepperNext>Siguiente</button>
                            </div>

                        </mat-step>
                        <mat-step [stepControl]="publicidadForm" label="Datos Generales">
                            <div *ngIf="contenidoPublicacion">
                                <label>Descripción de la publicación</label>
                                <textarea rows="4" class="form-control" (change)="validacionForm()"
                                    formControlName="summary">Descripción.</textarea>
                                <div class="mt-3">
                                    <h4>Cargar imagen</h4>
                                    <ngx-dropzone (change)="onSelect($event)">
                                        <ngx-dropzone-label>Soltar Imagen o Cargargar imagen.
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                            *ngFor="let f of files" [file]="f">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>
                                </div>

                            </div>
                            <div>
                                <button mat-button matStepperPrevious>Regresar</button>
                                <button mat-button matStepperNext>Siguiente</button>
                            </div>

                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Resumen</ng-template>
                            <div class="col-md-12">
                                <p>Tipo de publicacion: {{publicidadForm.get('topicType')?.value}}</p>
                                <p>Acción de la publicación: {{publicidadForm.get('callToAction')?.value}}</p>
                                <p>Url: {{publicidadForm.get('urlAction')?.value}}</p>
                                <div *ngIf="oferta">
                                    <p>Fecha Inicio:  {{publicidadForm.get('fstart')?.value}} </p>
                                    <p>Fecha Fin:  {{publicidadForm.get('fend')?.value}}</p>
                                </div>
                                <p>Descripción: {{publicidadForm.get('summary')?.value}}</p>
                                
                            </div>
                                <span class="red"> {{mensaje}}</span>
                            <div>
                                <button mat-button matStepperPrevious>Regresar</button>
                                <button mat-button (click)="submitPublicidad()">Publicar</button>
                            </div>
                        </mat-step>
                    </form>
                </mat-stepper>
            </div>
        </div>





    </div>

</div>