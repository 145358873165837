import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Modulos
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { PagesComponent } from './pages.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RjxComponent } from './rjx/rjx.component';
import { GoogleChartsModule } from 'angular-google-charts';

import { RegisterClienteComponent } from './register-cliente/register-cliente.component';
import { InscripcionesComponent } from './inscripciones/inscripciones.component';
import { MensualidadesComponent } from './mensualidades/mensualidades.component';
import { InscripcionesAvencerComponent } from './inscripciones-avencer/inscripciones-avencer.component';
import { InstructoresComponent } from './instructores/instructores.component';
import { ConfigNegocioComponent } from './config-negocio/config-negocio.component';
import { QrComponent } from './qr/qr.component';
import { ResizableModule } from 'angular-resizable-element';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { UsuarioComponent } from './usuario/usuario.component';
import { RespuestasComponent } from './respuestas/respuestas.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReviewsComponent } from './reviews/reviews.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { ConfigRolesComponent } from './config-roles/config-roles.component';
import { AnaliticsComponent } from './analitics/analitics.component';
import { NgChartsModule } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import { SoketioService } from '../services/soketio.service';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
@NgModule({
  declarations: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent,
    PromesasComponent,
    RjxComponent,
    RegisterClienteComponent,
    InscripcionesComponent,
    MensualidadesComponent,
    InscripcionesAvencerComponent,
    InstructoresComponent,
    ConfigNegocioComponent,
    QrComponent,
    UsuarioComponent,
    RespuestasComponent,
    ReviewsComponent,
    PreguntasComponent,
    ConfigRolesComponent,
    AnaliticsComponent,
    PublicacionesComponent,

  ],
  exports: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    GoogleChartsModule,
    ResizableModule,
    FormsModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    NgChartsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    NgxDropzoneModule
  ],
  providers: [SoketioService]
})
export class PagesModule { }
