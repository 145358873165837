import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UsuariosService } from '../../services/usuarios.service';
import { Subscription } from 'rxjs';
import { NotificacionesService } from '../../services/notificaciones.service';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { SoketioService } from '../../services/soketio.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  imgFinalUser: string = '';
  nombreFinal: string = '';
  notificacion: any;
  subscription: Subscription;
  prendernotificacion: boolean = true; //CAMBIAR A TRUE PARA QUE FUNCIONE SOLO 


  constructor(
    private router: Router,
    private authServices: AuthService,
    private UsuariosService: UsuariosService,
    private NotificacionesService: NotificacionesService,
    private SoketioService: SoketioService
  ) {
    this.nombreFinal = UsuariosService.nombreFinal(sessionStorage.getItem('nombre'));
    this.nombreFinal = UsuariosService.nombreFinal(sessionStorage.getItem('nombre'));
    this.subscription = UsuariosService.change$.subscribe(() => {
      this.imgFinalUser = UsuariosService.imgNegocioFinal(sessionStorage.getItem('imgNegocio'));
    });
  }
  ngOnInit(): void {
    this.SoketioService.socketConect();
    this.SoketioService.socketNotificacion();

    this.SoketioService.outEven.subscribe(res => {
      this.notificacionPush(res);
    })

    this.getNoti();


  }

  logout(): void {
    this.authServices.logout();
    this.router.navigateByUrl('/login');
  }

  notificacionPush(data) {
    const noti = JSON.parse(data.data);
    let datanoti = [];
    // Obtener notificaciones de local storage
    const getNotificacionesStorage = localStorage.getItem('notificacion') !== null ? localStorage.getItem('notificacion') : null;

    if (getNotificacionesStorage !== null) {
      datanoti = JSON.parse(getNotificacionesStorage);
      if (datanoti.length === 10) {
        datanoti.pop();
      }
      datanoti.push(noti);
      localStorage.setItem('notificacion', JSON.stringify(datanoti));
    } else {
      datanoti.push(noti);
      localStorage.setItem('notificacion', JSON.stringify(datanoti));
      this.notificacion = [...datanoti];
    }
    this.getNoti()
    this.prendernotificacion = false;
    this.start();
  }

  start() {
    let color = '';
    if (!this.prendernotificacion) {
      color = `text-danger`
    } else {
      color = '';
    }
    return color
  }
  enf() {
    this.prendernotificacion = true;
    this.start();
  }

  getNoti() {
    const notis = JSON.parse(localStorage.getItem('notificacion'));
    if (notis !== null) {
      notis.map((x) => {
        let icon = '';
        switch (x.type) {
          case 'NEW_REVIEW':
            x.icon = `fa fa-star fa-1x`;
            x.location = './reviews';
            break;
          case 'UPDATED_REVIEW':
            x.icon = 'fa fa-star fa-1x'
            x.location = './reviews';
            break
          case 'NEW_QUESTION':
            x.icon = `fa fa-question-circle-o fa-1x`
            x.location = './preguntas';
            break
          default:
            x.icon = `fa fa-question-circle-o fa-1x`
            break;
        }
        return x
      });
      this.notificacion = notis;
    }
  }
  trans(data) {
    let tra = '';
    switch (data) {
      case 'NEW_REVIEW':
        tra = ' NUEVA RESEÑA ';
        break;
      case 'UPDATED_REVIEW':
        tra = ' NUEVA RESEÑA ACTUALIZADA ';
        break;
      case 'NEW_QUESTION':
        tra = ' NUEVA PREGUNTA ';
        break;

      default:
        break;
    }
    return tra
  }

}
