import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  obtenerLocation(locationAutosur: string, google_token: string) {
    const url = `${base_url}/location/${locationAutosur}/${google_token}`
    return this.http.get(url)
  }
}
