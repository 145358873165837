<div class="fondoModal animated fadeIn fast" [class.oculto]="ModalService.ocultaMordalInscripcion">
    <div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1"
        style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel1">Mensualidades</h4>                   
                </div>
                <div class="modal-body">
                    <h4>Selecciona una mensualidad</h4>
                <select class="form-control" [(ngModel)]="valor">
                    <option *ngFor="let item of listaMensualidades" [ngValue]="item.id">{{item.nombre}} - {{item.meses}} (MESES)</option>
                </select>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cerrarModal()">Close</button>
                    <button type="button" class="btn btn-primary" (click)="aceptar();">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
</div>