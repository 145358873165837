import { Component, OnInit } from '@angular/core';
import { MensualidadesService } from '../../services/mensualidades.service';
import { Acciones } from '../../module/acciones.module';
import { Mensualidades } from '../../interfaces/mensualidades.interfaces';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-mensualidades',
  templateUrl: './mensualidades.component.html',
  styleUrls: ['./mensualidades.component.css']
})
export class MensualidadesComponent implements OnInit {
  accionPagina = Acciones.Listar;
  listaMensualidades: Mensualidades[] = [];
  loader: boolean = false;

  mensualidadForm = this.fb.group({
    id: [null],
    nombre: [null, [Validators.required, Validators.minLength(1)]],
    meses: [null, [Validators.required, Validators.minLength(1), Validators.pattern('^[0-9]*$')]],
    costo: [null, [Validators.required, Validators.minLength(1), Validators.pattern('^[0-9]*$')]],
    status: [1]
  })
  mensajeError: string;
  constructor(private MensualidadesService: MensualidadesService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.obtenerListadoMensualidades();
  }

  obtenerListadoMensualidades(): void {
    this.MensualidadesService.obtenerListadoMensualidades().subscribe((resp: ResponseGeneral) => {
      const listaMensualidades = resp.data

      listaMensualidades.map((res: Mensualidades) => {
        if (res.status === 1) {
          res.status = 'Activo';
        } else {
          res.status = 'Inactivo';
        }
        if(res.costo !== null){
          let costo = Number(res.costo);
          res.costo = `$ ${costo.toFixed(2)}`
        }
        return res;
      });
      this.loader = true;
      this.listaMensualidades = [...listaMensualidades];
    });

  }

  submitMensualidades(): void {
    switch (this.accionPagina) {
      case Acciones.Actualizar:
        this.updateMensualidades();
        break;

      default:
        this.crearMensualidad();
        break;
    }
  }

  nuevaMensualidad(): void {
    this.accionPagina = Acciones.Crear;
    this.mensualidadForm.reset();
  }
  crearMensualidad(): void {
    this.MensualidadesService.crearMensualidad(this.mensualidadForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerListadoMensualidades();
      Swal.fire('Correcto', resp.msg, 'success');
      this.accionPagina = Acciones.Listar;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  actualizarMensualidad(data: Mensualidades): void {
    this.mensualidadForm.reset();
    this.mensualidadForm.get('id')?.setValue(data.id);
    this.mensualidadForm.get('nombre')?.setValue(data.nombre);
    this.mensualidadForm.get('meses')?.setValue(data.meses);
    this.mensualidadForm.get('status')?.setValue(1);
    this.mensualidadForm.get('costo')?.setValue(data.costo);
    this.accionPagina = Acciones.Actualizar
  }

  updateMensualidades(): void {
    this.MensualidadesService.actualizarMensualidad(this.mensualidadForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerListadoMensualidades();
      this.accionPagina = Acciones.Listar;
      Swal.fire('Correcto', resp.msg, 'success');
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }
  deleteMensualidad(data: Mensualidades): void {

    Swal.fire({
      title: `Estas Seguro ?`,
      text: `Se eliminara la mensualidad`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.MensualidadesService.eliminarMensualudad(Number(data.id)).subscribe((resp: ResponseGeneral) => {
          this.obtenerListadoMensualidades();
          Swal.fire('Correcto', resp.msg, 'success');
          this.accionPagina = Acciones.Listar;
        }, (err) => {
          Swal.fire('Error', err.error.msg, 'error');
          this.obtenerListadoMensualidades();
        })
      }
    })
  }

  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.mensualidadForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }

  cancelarMensualidad(): void {
    this.obtenerListadoMensualidades();
    this.loader = false;
    this.accionPagina = Acciones.Listar;
  }
}
