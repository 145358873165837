import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { DashboardService } from '../../services/dashboard.service';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { map } from 'rxjs/operators';
import { Dashboard } from '../../interfaces/dashboard.interfaces';
import { UsuariosService } from '../../services/usuarios.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
  ]
})
export class DashboardComponent implements OnInit {

  valorIMg: boolean = true;
  respuestas: number = 0
  clientes: number = 0;

  constructor(public ModalService: ModalService, private DashboardService: DashboardService, private UsuariosService: UsuariosService) { }


  async ngOnInit(): Promise<void> {
    // await this.configNegocio();
    this.dashboardTablero();
  }
  cambioValorHijo(valor: boolean) {
    console.log(valor);
  }




  dashboardTablero(): void {
    this.DashboardService.dashboars().subscribe((resp: ResponseGeneral) => {
      const resultDashboars: Dashboard = resp.data[0];
      this.clientes = resultDashboars.Clientes;
      this.respuestas = resultDashboars.Respuestas
    });
  }
}
