import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Mensualidades } from '../interfaces/mensualidades.interfaces';

const base_url = environment.base_url
@Injectable({
  providedIn: 'root'
})
export class MensualidadesService {

  constructor(private http: HttpClient) { }

  obtenerListadoMensualidades() {
    const url = `${base_url}/mensualidades/`
    return this.http.get(url);
  }

  crearMensualidad(data: Mensualidades) {
    const url = `${base_url}/mensualidades/new`;
    return this.http.post(url, data);
  }
  actualizarMensualidad(data: Mensualidades) {
    const url = `${base_url}/mensualidades/edit`;
    return this.http.put(url, data);
  }
  eliminarMensualudad(idMensualidad: number) {
    const url = `${base_url}/mensualidades/dele/${idMensualidad}`;
    return this.http.delete(url);
  }

}
