import { Component, OnInit } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';


@Component({
  selector: 'app-grafica1',
  templateUrl: './grafica1.component.html',
  styleUrls: ['./grafica1.component.css']
})
export class Grafica1Component implements OnInit {
  chartData = {
    type: 'LineChart',
    data: [
      ["Jan", 500, 600],
      ["Feb", 800, 900],
      ["Mar", 400, 600],
      ["Apr", 600, 500],
      ["May", 400, 300],
      ["Jun", 750, 700],
      ["Jul", 800, 710],
      ["Aug", 810, 720],
      ["Sep", 820, 730],
      ["Oct", 900, 840],
      ["Nov", 910, 850],
      ["Dec", 920, 890]
    ],
    columnNames: ["Month", "Apple", "Mi"],
    options: {
      hAxis: {
        title: 'Month'
      },
      vAxis: {
        title: 'Sell'
      },
    },
    width: 300,
    height: 100,
  };
  chartData2 = {
    type: 'LineChart',
    data: [
      ["Jan", 500, 600],
      ["Feb", 800, 900],
      ["Mar", 400, 600],
      ["Apr", 600, 500],
      ["May", 400, 300],
      ["Jun", 750, 700],
      ["Jul", 800, 710],
      ["Aug", 810, 720],
      ["Sep", 820, 730],
      ["Oct", 900, 840],
      ["Nov", 910, 850],
      ["Dec", 920, 890]
    ],
    columnNames: ["Month", "Apple", "Mi"],
    options: {
      hAxis: {
        title: 'Month'
      },
      vAxis: {
        title: 'Sell'
      },
    },
    width: 300,
    height: 100,
  };
  public style: object = {};
  public padreStyle: object = {};

  leftPosition: number = 0;
  topPosition: number = 0;
  divWidth: number = 0;
  divHeight: number = 0;
  public style2: object = {};
  leftPosition2: number = 0;
  topPosition2: number = 0;
  divWidth2: number = 0;
  divHeight2: number = 0;


  leftPosition3: number = 0;
  topPosition3: number = 0;
  divWidth3: number = 0;
  divHeight3: number = 0;


  constructor() { }

  ngOnInit(): void {

  }
  onResizeEnd(event: ResizeEvent): void {
    console.log(event);
    this.leftPosition = event.rectangle.left;
    this.topPosition = event.rectangle.top;
    this.divWidth = event.rectangle.width;
    this.divHeight = event.rectangle.height;
    this.style = {
      position: 'fixed',
      left: `${this.leftPosition}px`,
      top: `${this.topPosition}px`,
      width: `${this.divWidth}px`,
      height: `${this.divHeight}px`
    };
    this.chartData.height = (this.divHeight - 30);
    this.chartData.width = (this.divWidth - 30);
  }
  onResizeEnd2(event: ResizeEvent): void {
    console.log(event);
    this.leftPosition2 = event.rectangle.left;
    this.topPosition2 = event.rectangle.top;
    this.divWidth2 = event.rectangle.width;
    this.divHeight2 = event.rectangle.height;
    this.style2 = {
      position: 'fixed',
      left: `${this.leftPosition2}px`,
      top: `${this.topPosition2}px`,
      width: `${this.divWidth2}px`,
      height: `${this.divHeight2}px`
    };
    this.chartData2.height = (this.divHeight2 - 30);
    this.chartData2.width = (this.divWidth2 - 30);
  }
  onResizeEnd3(event: ResizeEvent): void {
    console.log(event);
    this.leftPosition3 = event.rectangle.left;
    this.topPosition3 = event.rectangle.top;
    this.divWidth3 = event.rectangle.width;
    this.divHeight3 = event.rectangle.height;

    this.padreStyle = {
      position: 'fixed',
      left: `${this.leftPosition3}px`,
      top: `${this.topPosition3}px`,
      width: `${this.divWidth3}px`,
      height: `${this.divHeight3}px`
    };
    this.style = {
      position: 'fixed',
      left: `${this.leftPosition3 -30 }px`,
      top: `${this.topPosition3 -30 }px`,
      width: `${this.divWidth3 -30}px`,
      height: `${this.divHeight3 - 30}px`
    }
    this.style2 = {
      position: 'fixed',
      left: `${this.leftPosition3 -30}px`,
      top: `${this.topPosition3 -30}px`,
      width: `${this.divWidth3 - 30}px`,
      height: `${this.divHeight3 -30}px`
    }
    this.chartData.height = (420 - 20);
    this.chartData.width = (this.divWidth3 /2);

    this.chartData2.height = (this.divHeight3 /2 +20);
    this.chartData2.width = (this.divWidth3 /2 + 20);
  }



  public labels1: string[] = ['Pan', 'Refresco', 'Tacos'];
  public data1 = [
    [10, 15, 40],
  ];

}
