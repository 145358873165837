import { Component, OnInit } from '@angular/core';
import { Acciones } from '../../module/acciones.module';
import { RespuestasService } from '../../services/respuestas.service';
import { respuestas } from '../../interfaces/respuestas.interfaces';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import Swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-respuestas',
  templateUrl: './respuestas.component.html',
  styleUrls: ['./respuestas.component.css']
})
export class RespuestasComponent implements OnInit {
  loader: boolean = false;
  accionPagina = Acciones.Listar;
  Listadorespuestas: respuestas[] = [];

  respuestasForm = this.fb.group({
    nombre: [null, [Validators.required, Validators.minLength(1)]],
    id: [null]
  })
  mensajeError: string;
  constructor(private RespuestasService: RespuestasService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.obtenerListadorespuestas();
  }

  obtenerListadorespuestas(): void {
    this.RespuestasService.ObtenerListadoRespuestas().subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        const resultadoListado = resp.data;
        this.Listadorespuestas = [...resultadoListado];
      }
      this.loader = true;
    },
      (err) => {
        Swal.fire('Error', err.error.msg, 'error');
      })
  }

  crearRespuesta(): void {
    this.accionPagina = Acciones.Crear;
    this.respuestasForm.reset();
  }
  submitRespuesta(): void {
    switch (this.accionPagina) {
      case Acciones.Actualizar:
        this.actualizarRespuesta();
        break;

      default:
        this.crearNuevaRespuesta();
        break;
    }
  }
  crearNuevaRespuesta(): void {
    this.loader = false;
    this.RespuestasService.crearRespuesta(this.respuestasForm.value).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.respuestasForm.reset();
        Swal.fire('Correcto', resp.msg, 'success');
        this.accionPagina = Acciones.Listar;
        this.obtenerListadorespuestas();
      }
      this.loader = true;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }
  actualizarRespuesta(): void {
    this.loader = false;
    this.RespuestasService.actualizarRespuesta(this.respuestasForm.value).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.accionPagina = Acciones.Listar;
        Swal.fire('Correcto', resp.msg, 'success');
        this.obtenerListadorespuestas();
      }
      this.loader = true;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }
  modificarRespuesta(data: respuestas): void {
    this.respuestasForm.reset();
    this.respuestasForm.get('nombre')?.setValue(data.nombre);
    this.respuestasForm.get('id')?.setValue(data.id);
    this.accionPagina = Acciones.Actualizar;
  }
  cancelarRespuesta(): void {
    this.accionPagina = Acciones.Listar;
    this.respuestasForm.reset();
    this.loader = false;
    this.obtenerListadorespuestas();
  }
  elimiarRespuesta(data: respuestas) {
    Swal.fire({
      title: `Seguro ?`,
      text: `Se eliminar la respuesta ( ${data.nombre} )`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loader = false;
        this.RespuestasService.eliminarRespuesta(Number(data.id)).subscribe((resp: ResponseGeneral) => {
          if (resp.ok) {
            this.obtenerListadorespuestas();
            Swal.fire('Correcto', resp.msg, 'success')
          }
          this.loader = true;
        },
          (err) => {
            Swal.fire('Error', err.error.msg, 'error');
          })
      }
    })
  }

  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.respuestasForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }
}
