import { Component, OnDestroy } from '@angular/core';
import { Observable, interval, Subscription } from 'rxjs';
import { retry, take, map, filter } from 'rxjs/operators'

@Component({
  selector: 'app-rjx',
  templateUrl: './rjx.component.html',
  styleUrls: ['./rjx.component.css']
})
export class RjxComponent implements OnDestroy {

  intervalSubs: Subscription;

  constructor() {

    this.intervalSubs = this.retoranaIntervalo().subscribe((valor) => {
      console.log(valor);
    });
    // this.retornaObservale().pipe(
    //   retry(1)
    // ).subscribe(valor => console.log('suscruocion', valor), (err) => console.log('Error', err),
    //   () => console.log('terminado')
    // );
  }
  
  ngOnDestroy(): void {
    this.intervalSubs.unsubscribe();
  }

  retoranaIntervalo(): Observable<number> {
    return interval(1000).pipe(
      // take(4),
      map(valor => valor + 1),
      filter(valor => (valor % 2 === 0) ? true : false)
    );
  }

  retornaObservale(): Observable<number> {
    let i = -1;

    const obs$ = new Observable<number>(observer => {

      const inter = setInterval(() => {
        i++;
        observer.next(i);

        if (i === 4) {
          clearInterval(inter);
          observer.complete();
        }
        if (i === 2) {
          i = -1;
          observer.error('i llego al valor de 2');
        }
      }, 1000)
    });
    return obs$
  }


}
