import { Component, OnInit } from '@angular/core';
import { AnalitycsService } from '../../services/analitycs.service';
import { LocationService } from '../../services/location.service';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-analitics',
  templateUrl: './analitics.component.html',
  styleUrls: ['./analitics.component.css']
})
export class AnaliticsComponent implements OnInit {
  constructor(
    private AnalitycsService: AnalitycsService,
    private LocationService: LocationService,
    private AuthService: AuthService,
    private Router: Router,
    private fb: FormBuilder
  ) { }


  today = new Date();
  month = this.today.getMonth();
  year = this.today.getFullYear();
  day = this.today.getDay();

  campaignOne = this.fb.group({
    start: [null],
    end: [null]
  });
  campaignTwo = this.fb.group({
    start: [null],
    end: [null]
  });

  valor: string = '';
  lables: string[] = [];
  lables1: string[] = [];
  lables2: string[] = [];
  lables3: string[] = [];
  lables4: string[] = [];

  value: number[] = [];
  value1: number[] = [];
  value2: number[] = [];
  value3: number[] = [];
  value4: number[] = [];


  barChartType: ChartType = 'bar';
  pieChartType: ChartType = 'polarArea';
  piesChartType: ChartType = 'pie';
  lineChartType: ChartType = 'line';
  lineChartType2: ChartType = 'line';

  barChartData: ChartData<'bar'>;
  pieChartData: ChartData<'polarArea'>;
  piesChartData: ChartData<'pie'>;
  lineChartData: ChartData<'line'>;
  lineChartData2: ChartData<'line'>;

  five: number = 10;
  ford: number = 10;
  tree: number = 10;
  two: number = 10;
  one: number = 10;

  sinContestar: number = 0;
  contestadas: number = 0;


  get fives() {
    return `${this.five}%`
  }
  get fords() {
    return `${this.ford}%`
  }
  get trees() {
    return `${this.tree}%`
  }
  get twos() {
    return `${this.two}%`
  }
  get ones() {
    return `${this.one}%`
  }

  loader: boolean = false;
  location = [];
  dataAnalitycs: any;
  stars: any;
  ngOnInit(): void {
    this.obtenerLocations();

  }


  obtenerLocations() {
    this.LocationService.obtenerLocation(localStorage.getItem('locationAutosur'), localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.location = [...resp.data];
        this.analitycs();
      }
    })
  }

  analitycs() {
    let f1 = this.campaignOne.get('start')?.value;
    let f2 = this.campaignOne.get('end')?.value;

    this.AnalitycsService.analitycs(this.location, localStorage.getItem('google_token'), localStorage.getItem('locationAutosur'), f1, f2).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {

        this.dataAnalitycs = JSON.parse(resp.data);
        this.formarDataChart();
        this.start();
      }
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.analitycs();

        })
      } else {
        Swal.fire('Error', err.error.msg, 'error');
        this.Router.navigateByUrl('/');
      }
    })
  }
  start(one = null) {
    let location = [];

    if (one !== null) {
      location.push(this.valor)
    } else {
      this.location.map((x) => {
        location.push(x.name);
      });
    }
    this.AnalitycsService.obtenerStartsLocation(location, localStorage.getItem('locationAutosur'), localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.stars = JSON.parse(resp.data);
        this.five = this.stars.five;
        this.ford = this.stars.ford;
        this.tree = this.stars.tree;
        this.two = this.stars.two;
        this.one = this.stars.one;

        this.sinContestar = this.stars.snconstestada;
        this.contestadas = this.stars.contestada;

        this.loader = true;
      }
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.start();
        })
      } else {
        Swal.fire('Error', err.error.msg, 'error');
        this.Router.navigateByUrl('/');
      }
    })
  }

  barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    backgroundColor: '#84b6f4',
    borderColor: '#6a9eda',
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: true,
      }
    }
  };
  pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    backgroundColor: '#84b6f4',
    borderColor: '#6a9eda',
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  };
  piesChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    backgroundColor: '#84b6f4',
    borderColor: '#6a9eda',
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  };
  lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    backgroundColor: '#84b6f4',
    borderColor: '#6a9eda',
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: false,
      }
    }
  };

  oneAnalitics() {
    let f1 = this.campaignOne.get('start')?.value;
    let f2 = this.campaignOne.get('end')?.value;

    this.loader = false;
    const location = [{
      name: this.valor
    }]
    this.AnalitycsService.analitycs(location, localStorage.getItem('google_token'), localStorage.getItem('locationAutosur'), f1, f2).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        // this.loader = true;
        this.dataAnalitycs = JSON.parse(resp.data);
        this.formarDataChart();
        this.start(1);

      }
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.oneAnalitics();
        })
      } else {
        Swal.fire('Error', err.error.msg, 'error');
        this.Router.navigateByUrl('/');
      }
    })
  }

  formarDataChart() {
    this.dataAnalitycs.dataFInal_ACTIONS_PHONE.map((x) => {
      this.lables.push(x.fecha);
      this.value.push(x.value);
    })
    this.dataAnalitycs.dataFInal_ACTIONS_WEBSITE.map((w) => {
      this.lables1.push(w.fecha);
      this.value1.push(w.value);
    });
    this.dataAnalitycs.dataFInal_VIEWS_SEARCH.map((s) => {
      this.lables2.push(s.fecha);
      this.value2.push(s.value);
    });

    this.dataAnalitycs.dataFInal_QUERIES_INDIRECT.map((i) => {
      this.lables3.push(i.fecha);
      this.value3.push(i.value);
    });

    this.dataAnalitycs.dataFInal_QUERIES_DIRECT.map((d) => {
      this.lables4.push(d.fecha);
      this.value4.push(d.value);
    });

    this.barChartData = {
      labels: this.lables,
      datasets: [{ data: this.value, label: 'LLAMADAS TELEFONICAS' }]
    }
    this.pieChartData = {
      labels: this.lables1,
      datasets: [{ data: this.value1, label: 'ACCIONES DE SITIO' }]
    }
    this.piesChartData = {
      labels: this.lables2,
      datasets: [{ data: this.value2, label: 'BUSQUEDAS' }]
    }
    this.lineChartData = {
      labels: this.lables3,
      datasets: [{ data: this.value3, label: 'INDIRECTAS' }]
    }
    this.lineChartData2 = {
      labels: this.lables4,
      datasets: [{ data: this.value4, label: 'DIRECTAS' }]
    }

  }

  cargaXFecha(){
    this.loader = false;
    if(this.valor === ''){
      this.analitycs();
    } else {
      this.oneAnalitics();
    }
  }

}
