<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="accionPagina === 'Listar' && loader">
    <div class="col-12">
        <div class="d-flex justify-content-end mb-2">
        </div>
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-center mb-3">
                    <form [formGroup]="formInscripAvencer">
                        <div class="row">
                            <div class="form-group col-md-6 m-t-20">
                                <label>Filtrado por Meses</label>
                                <select class="form-control" (change)="search()" formControlName="select">
                                    <option *ngFor="let item of ListaMensualidades" [value]="item.meses">
                                        {{item.nombre}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 m-t-20">
                                <label>Filtrado por Tipo</label>
                                <select class="form-control" formControlName="tipo" (change)="search()">
                                    <option [value]="'todos'">Todos</option>
                                    <option [value]="'VIGENTE'">Vigentes</option>
                                    <option [value]="'SUSPENDIDO'">Suspendidos</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <h4 class="card-title">Lista Inscripciones a vencer (Mensuales) ( {{listadoInscripciones.length}} )
                </h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Mensualidad</th>
                                <th>Fecha Limite</th>
                                <th>Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lista of listadoInscripciones">
                                <td>{{lista.nombre}}</td>
                                <td>{{lista.meses}}</td>
                                <td>{{lista.created}}</td>
                                <td>
                                    <div *ngIf="lista.status === 'SUSPENDIDO'; then succ else fais"></div>
                                    <ng-template #succ>
                                        <span class="badge badge-danger">{{lista.status}}</span>
                                    </ng-template>
                                    <ng-template #fais>
                                        <span class="badge badge-success">{{lista.status}}</span>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn btn-primary mr-2">siguiente</button>

                    <button class="btn btn-primary">anterior</button>
                </div>
            </div>
        </div>
    </div>
</div>