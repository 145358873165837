import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Clientes, Usuarios } from '../interfaces/usuario.interfaces';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { responsesAdmin } from '../interfaces/response.interfaces';
import { BehaviorSubject } from 'rxjs';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  private _urlImg = 'no-Imagen';
  private _imgNegocio = 'no-Image';
  private _nombre = '';
  change$ = new BehaviorSubject(null);

  get urlImg() {
    return `${base_url}/upload/upload/clientes/${this._urlImg}`
  }
  get idUsuario() {
    return sessionStorage.getItem('id');
  }

  get imgNegocio() {
    return `${base_url}/upload/upload/negocio/${this._imgNegocio}`;
  }
  get nombre() {
    return this._nombre;
  }
  get idUsuari() {
    return sessionStorage.getItem('id');
  }

  constructor(private http: HttpClient) { }

  obtenerListaClientes(numeroClientes: number) {
    const url = `${base_url}/user/${numeroClientes}`;
    return this.http.get(url).pipe(
      tap((resp: any) => {
        if (resp.ok) {
          localStorage.setItem('numUsuarios', resp.num);
        }
      })
    )
  }

  crearUsuario(data: Clientes, instructor: number = 0, mensualidad: number = 0) {
    const url = `${base_url}/auth/new`;
    const body = {
      ...data,
      instructor: instructor,
      mensualidad: mensualidad
    }
    return this.http.post(url, body);
  }

  buscarCliente(nombre: string) {
    const url = `${base_url}/user/busquedaCliente/${nombre}`;
    return this.http.get(url);
  }

  actualizarCliente(data: Clientes) {
    const url = `${base_url}/user/edit`;
    return this.http.put(url, data);
  }

  deleteCliente(data: Clientes) {
    const url = `${base_url}/user/dele/${data.id}`;
    return this.http.delete(url)
  }

  imgUrlFinal(id: string) {
    this._urlImg = id;
    return this.urlImg;
  }
  imgNegocioFinal(nombre: string) {
    this._imgNegocio = nombre;
    return this.imgNegocio
  }
  nombreFinal(nombre: string) {
    this._nombre = nombre;
    return this.nombre
  }


  /**
   * SERVICIOS DE USUARIOS ADMINISTRADORES Y ASESORES
   */

  CrearUsuario(data: Usuarios) {
    const url = `${base_url}/user/createdUser`;
    return this.http.post(url, data)
  }
  ActualizarUsuario(data: Usuarios) {
    const url = `${base_url}/user/updateUsuario`;
    return this.http.put(url, data);
  }
  DeleteUsuario(id: number) { 
    const url = `${base_url}/user/deleteUser/${id}`;
    return this.http.delete(url);
  }
}
