import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuariosService } from '../services/usuarios.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  
  session: boolean = true;
  constructor(private UsuariosService: UsuariosService,private Router:Router ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      if(this.UsuariosService.idUsuari === null){
        this.session = false;
        localStorage.removeItem('token');
        this.Router.navigateByUrl('/login');
        return false;
      }
    return true
  }
}
