import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ResponseGeneral, responsesAdmin } from '../interfaces/response.interfaces';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }


  dashboars() {
    const url = `${base_url}/dashboard/`;
    return this.http.get(url);
  }

  getConfigNegocio(user_id: number) {
    
    const url = `${base_url}/configlocal/getNegocio/${user_id}`;
    return this.http.get(url)
  }

}
