import { Component, OnInit } from '@angular/core';
import { Acciones } from 'src/app/module/acciones.module';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { PublicacionesService } from '../../services/publicaciones.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.css']
})
export class PublicacionesComponent implements OnInit {
  accionPagina = null;
  loader: boolean = false;
  location = [];
  listadoPublicaciones = [];
  page: number = 1;
  locationsSelect: string = '-- SELECCIONE --';
  tipoPublicacion: string = '0';
  contenidoPublicacion: boolean = false;
  NombreLocation: string = '';
  buscador: boolean = true;
  oferta: boolean = false;
  files: File;
  mensaje: string;
  constructor(
    private LocationService: LocationService,
    private AuthService: AuthService,
    private PublicacionesService: PublicacionesService,
    private Router: Router,
    private fb: FormBuilder
  ) { }

  publicidadForm = this.fb.group({
    summary: [null],
    topicType: [0],
    callToAction: [0],
    fstart: [null],
    fend: [null],
    urlAction: [null]
  });
  async ngOnInit(): Promise<void> {
    await this.obtenerLocation();
    console.log(this.publicidadForm.value);

  }

  getNombre() {
    const nombre = this.location.find((x) => x.name === this.locationsSelect);
    this.NombreLocation = nombre.title;
  }

  async obtenerLocation(): Promise<void> {
    this.loader = false;
    this.LocationService.obtenerLocation(localStorage.getItem('locationAutosur'), localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.location = [...resp.data];

        this.loader = true;
      }
    }, (err) => {
      console.log(err);

      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.obtenerLocation();
        })
      } else {
        Swal.fire('Error', err.error.msg, 'error');
        this.Router.navigateByUrl('/');
      }
    })
  }

  getlistadoPublicaciones(): void {
    this.loader = false;
    const urlLocation = `${localStorage.getItem('locationAutosur')}/${this.locationsSelect}`
    this.PublicacionesService.getListadoPublicaciones(urlLocation, localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      console.log(resp);

      this.SearchTypePublicacion(resp.data.localPosts);
      console.log(resp.data.localPosts);
      this.loader = true;
      this.accionPagina = Acciones.Listar
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.getlistadoPublicaciones();
        })
      } else {
        Swal.fire('Error', err.error.msg, 'error');
        this.Router.navigateByUrl('/');
      }
    })
  }

  SearchTypePublicacion(data: any) {
    this.listadoPublicaciones = [];
    let arrayData = [];
    data.map((x) => {
      let dataType = {
        img: '',
        titulo: '',
        type: '',
        id: '',
        creacion: '',
      };
      switch (x.topicType) {
        case 'OFFER':
          dataType.img = x.hasOwnProperty('media') ? x.media[0].googleUrl : '';
          dataType.titulo = x.hasOwnProperty('event') ? x.event.title : '';
          dataType.type = 'OFERTA';
          dataType.id = x.name;
          dataType.creacion = x.createTime
          break;
        case 'STANDARD':
          dataType.img = x.hasOwnProperty('media') ? x.media[0].googleUrl : '';
          dataType.titulo = x.summary;
          dataType.type = 'ESTANDAR';
          dataType.id = x.name;
          dataType.creacion = x.createTime;
          break;
        case 'EVENT':

          break;
      }
      arrayData.push(dataType);
    });
    this.listadoPublicaciones = [...arrayData];
  }

  crearPublicacion() {
    this.buscador = false;
    this.getNombre();
    this.accionPagina = Acciones.Crear;
    this.tipoPublicacion = "0";
    this.publicidadForm.get('topicType')?.setValue('0');
    this.publicidadForm.get('callToAction')?.setValue('0');
    this.publicidadForm.get('fend')?.reset(null);
    this.publicidadForm.get('fstart')?.reset(null); 
    this.publicidadForm.get('urlAction')?.reset(null);
    this.oferta = false;
  }
  regresarListado() {
    this.buscador = true;
    this.tipoPublicacion = "0";
    this.publicidadForm.get('topicType')?.setValue('0');
    this.publicidadForm.get('callToAction')?.setValue('0');
    this.publicidadForm.get('fend')?.reset(null);
    this.publicidadForm.get('fstart')?.reset(null);
    this.publicidadForm.get('urlAction')?.reset(null);
    this.oferta = false;
    this.accionPagina = Acciones.Listar;
  }

  contenidoPublicacionMethod() {
    this.contenidoPublicacion = true;
    if (this.publicidadForm.get('topicType')?.value === 'OFFER') {
      this.oferta = true;
    } else {
      this.oferta = false;
    }
  }
  submitPublicidad() {
    const validacion = this.validacionForm();

    if (validacion) {
      this.crearPublicacionFinal()
    } else {
      this.mensaje = 'Faltan datos por acompletar'
      return
    }
  }
  crearPublicacionFinal() {
    this.loader = false;
    this.PublicacionesService.crearPublicacion(this.publicidadForm.value, this.files[0],
      localStorage.getItem('locationAutosur'), localStorage.getItem('google_token'), this.locationsSelect).subscribe((resp: ResponseGeneral) => {
       if(resp.ok){
        this.getlistadoPublicaciones();
       }
      }, (err) => {
        Swal.fire('Error', err.error.msg, 'error');
        this.getlistadoPublicaciones();
      })
  }

  validacionForm(): boolean {
    let valids: boolean = false;
    if (this.publicidadForm.get('topicType')?.value === 'OFFER') {
      if ((!!this.publicidadForm.get('fend')?.value !== null && !!this.publicidadForm.get('summary')?.value !== null)) {
        if (this.publicidadForm.get('summary')?.value !== '') {
          valids = true;
          this.mensaje = '';
        } else {
          valids = false;
        }
      }
    }
    if (this.publicidadForm.get('topicType')?.value === 'STANDARD') {
      if (this.publicidadForm.get('summary')?.value !== null || this.publicidadForm.get('summary')?.value !== '') {
        if (this.publicidadForm.get('summary')?.value !== '') {
          valids = true;
          this.mensaje = '';
        } else {
          valids = false;
        }
      }
    }
    return valids
  }

  onSelect(event) {
    console.log(event);
    this.files = event.addedFiles;
  }

  onRemove(event) {
    console.log(event);
    this.files = null;
  }

  deletePublicacion(name: string) {
    Swal.fire({
      title: `Estas Seguro ?`,
      text: `Se eliminara la publicacion y ya no se podra recuperar`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.PublicacionesService.deletepublicacion(name, localStorage.getItem('google_token')).subscribe((resp:ResponseGeneral) => {  
          this.buscador = true;        
          this.getlistadoPublicaciones();
        }, (err) => {
          Swal.fire('Error', 'Error al eliminar publicación', 'error')
        });
      }
    });
  }

}
