import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from '../../services/register.service';
import { responsesAdmin } from '../../interfaces/response.interfaces';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  mensajeError: string = '';
  registerForm = this.fb.group({
    nombre: ['Ricardo Leonel garcia Hernandez', [Validators.required]],
    correo: ['abs@gmmail.com', [Validators.required, Validators.email]],
    direccion: ['tulipanes', [Validators.required]],
    edad: [12, [Validators.required, Validators.minLength(1)]],
    telefono: [12345678, [Validators.required]],
    dni: ['uduewnduen', [Validators.required]],
    password: ['abover12', [Validators.required, Validators.minLength(6)]]
  });
  constructor(private fb: FormBuilder, private RegisterService: RegisterService, private router: Router) { }


  submitForms() {
    if (this.registerForm.invalid) {
      return;
    }

    this.RegisterService.crearUsuario(this.registerForm.value).subscribe((resp: responsesAdmin) => {
      if(resp.ok){
        this.router.navigateByUrl('/login');
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  public campoNoValido(campo: string):boolean {
    let ok = true;
    if(this.registerForm.get(campo)?.errors){
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }


}
