<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="alert  text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 ">
                    <label>Selecciona un role</label>
                    <select class="form-control" (change)="obtenerPermisos($event.target.value)">
                        <option [value]="0" disabled selected>-- Seleccione --</option>
                        <option *ngFor="let item of listaRoles" [value]="item.id">{{item.descripcion}}</option>
                    </select>
                </div>
                <div class="col-md-6 br">
                    <div class="d-flex justify-content-center">
                        <label>Permisos</label>
                    </div>
                    <div class="row">
                        <div class="col-md-4" *ngFor="let per of permisos">
                            <input type="checkbox" (change)="PermisoChange($event.target.checked,per.valor)" class="check" [(ngModel)]="per.checket"><span class="ml-3">{{per.permiso}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>