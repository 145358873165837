import { Component, OnInit } from '@angular/core';
import { InstructoresService } from '../../services/instructores.service';
import { Acciones, Pasos } from '../../module/acciones.module';
import { FormBuilder, Validators } from '@angular/forms';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { instructoresModel } from '../../interfaces/instructores.interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-instructores',
  templateUrl: './instructores.component.html',
  styleUrls: ['./instructores.component.css']
})
export class InstructoresComponent implements OnInit {
  loader: boolean = false;
  accionPagina = Acciones.Listar;
  listaInstructores: instructoresModel[] = [];
  siguientePaginacion: boolean = true;
  anteriorPaginacion:boolean = true;

  instructorForm = this.fb.group({
    id:[],
    nombre: [null, [Validators.required, Validators.minLength(1)]],
    edad: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
    direccion: [null, [Validators.required, Validators.minLength(1)]],
    correo: [null, [Validators.required, Validators.email]],
    telefono: [null, [Validators.required, Validators.minLength(1)]],
    dni:[null,[Validators.required, Validators.minLength(1)]],
  })
  mensajeError: string;
  constructor(private InstructoresService: InstructoresService, private fb: FormBuilder) { }

  ngOnInit(): void {
    localStorage.setItem('numInstructor', '10');
    this.obtenerInstructores();
  }

  obtenerInstructores(numCliente = localStorage.getItem('numInstructor')): void {
    this.InstructoresService.ontenerInstructores(Number(numCliente)).subscribe((resp: ResponseGeneral) => {
      const listaInstructores = resp.data;
      this.siguientePaginacion = resp.siguiente;
      this.anteriorPaginacion = resp.anterior;
      this.listaInstructores = [...listaInstructores];
      this.loader = true;
    });
  }

  nuevoInstructor(): void {
    this.accionPagina = Acciones.Crear;
    this.instructorForm.reset();
  }

  siguiente(){
    this.loader = false;
    let numClentes = Number(localStorage.getItem('numInstructor'));
    numClentes = numClentes + 10
    this.obtenerInstructores(numClentes.toString());
  }
  anterior(){
    this.loader = false;
    let numCLiente = Number(localStorage.getItem('numInstructor'));
    numCLiente = numCLiente - 10;
    this.obtenerInstructores(numCLiente.toString());
  }
  actualizarInstructor(data: instructoresModel): void {
    this.instructorForm.reset();
    this.instructorForm.get('id')?.setValue(data.id)
    this.instructorForm.get('nombre')?.setValue(data.nombre);
    this.instructorForm.get('edad')?.setValue(data.edad);
    this.instructorForm.get('direccion')?.setValue(data.direccion);
    this.instructorForm.get('correo')?.setValue(data.correo);
    this.instructorForm.get('telefono')?.setValue(data.telefono);
    this.instructorForm.get('dni')?.setValue(data.DNI);
    this.accionPagina = Acciones.Actualizar;
  }

  deleteInstructor(data: instructoresModel): void {
    Swal.fire({
      title: `Estas Seguro ?`,
      text: `Se eliminara el instructor`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.loader = false;
        this.InstructoresService.deleteInstructor(Number(data.id)).subscribe((resp: ResponseGeneral) => {
          this.obtenerInstructores();
          this.loader = true;
          Swal.fire('Correcto',resp.msg, 'success')
        }, (err) => {
          Swal.fire('Error', err.error.msg, 'error')
        });
      }
    })
  }

  updateInstructor(): void {
    this.InstructoresService.updateInstructor(this.instructorForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerInstructores();
      this.loader = true;
      this.accionPagina = Acciones.Listar;
      Swal.fire('Correcto',resp.msg, 'success')
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    });
  }

  crearNewInstructor(): void {
    this.InstructoresService.crearInstructor(this.instructorForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerInstructores();
      this.loader = true;
      this.accionPagina = Acciones.Listar;
      Swal.fire('Correcto',resp.msg, 'success');
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }

  submitInstructor(): void {
    this.loader = false;
    switch (this.accionPagina) {
      case Acciones.Actualizar:
        this.updateInstructor();
        break;

      default:
        this.crearNewInstructor();
        break;
    }
  }

  cancelarInstructor():void {
    this.loader = false;
    this.obtenerInstructores();
    this.accionPagina = Acciones.Listar;
  }

  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.instructorForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }

}
