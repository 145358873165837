import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { LocationService } from '../../services/location.service';
import Swal from 'sweetalert2';
import { ReviewsService } from '../../services/reviews.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { RespuestasService } from '../../services/respuestas.service';
import { respuestas } from '../../interfaces/respuestas.interfaces';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  @ViewChildren('cuadro') cuadro: QueryList<ElementRef>
  todosReview: boolean = false;
  sn: boolean = false;
  location = [];
  loaderOne: boolean = false;
  loader: boolean = true;
  loaderVerMas: boolean = false;
  valor: string = '';
  reviews = [];
  dataOriginal: string = '';
  comentBtn: boolean = false;

  respuestas: respuestas[] = [];
  start = '<p><i class="fa fa-star" aria-hidden="true"></i></p>';
  constructor(
    private Router: Router,
    private LocationService: LocationService,
    private ReviewsService: ReviewsService,
    private AuthService: AuthService,
    private RespuestasService: RespuestasService) { }

  async ngOnInit(): Promise<void> {
    await this.obtenerLocation();
    await this.obtenerRespuestas();
    localStorage.setItem('scroll', '0');
  }

  async obtenerRespuestas(): Promise<void> {
    this.RespuestasService.ObtenerListadoRespuestas().subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.respuestas = [...resp.data];
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }

  async obtenerLocation(): Promise<void> {
    this.loader = false;
    this.LocationService.obtenerLocation(localStorage.getItem('locationAutosur'), localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.location = [...resp.data];
        this.obtenerResenias();
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  obtenerResenias(): void {
    this.todosReview = false;
    let locations = [];
    this.location.map((r) => {
      locations.push(r.name);
    })
    this.ReviewsService.obtnerResenias(locations, localStorage.getItem('google_token'), localStorage.getItem('locationAutosur')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.reviews = [...resp.data.locationReviews];
        this.reviews.map((x) => {
          x.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === x.name);
          x.start = this.numberStart(x.review.starRating);
          return x;
        });

        localStorage.setItem('dataOriginal', JSON.stringify(this.reviews));


        if (resp.data.nextPageToken) {
          localStorage.setItem('nextPageReview', resp.data.nextPageToken);
        } else {
          localStorage.removeItem('nextPageReview');
        }
        this.loader = true;

      }
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.obtenerLocation();
        })
      } else {
        Swal.fire('Error', err.error.msg, 'error');
        this.Router.navigateByUrl('/');
      }
    })
  }



  numberStart(num: number | string) {
    let icon = [];
    let i: number = 0;
    switch (num) {
      case 'FIVE':
        i = 5;
        break;
      case 'FOUR':
        i = 4;
        break;
      case 'THREE':
        i = 3;
        break;
      case 'TWO':
        i = 2;
        break;
      case 'ONE':
        i = 1;
        break;
    }
    for (let index = 0; index < i; index++) {
      icon.push(`fa fa-star`)
    }
    return icon;
  }

  showcomment(item: any) {
    let ok = false;
    const existCooment = item.review.hasOwnProperty('comment');
    if (existCooment) {
      ok = true;
    }
    return ok;
  }
  showcommentAutoSur(item: any) {
    let ok = false;
    const existResponse = item.review.hasOwnProperty('reviewReply');
    if (existResponse) {
      ok = true;
    }
    return ok;
  }
  existreviewReviewer(item: any) {
    let ok = false;
    const existResponse = item.hasOwnProperty('review');
    if (existResponse) {
      ok = true;
    }
    return ok;
  }
  comentario(item: any, i: number): void {
    const cu: ElementRef = this.cuadro.find((c: ElementRef) => c.nativeElement.attributes.id.nodeValue === `comentario-${i}`);

    if (cu.nativeElement.value === '') {
      Swal.fire('Error', 'no puedes enviar un comentaio vacio', 'error');
      return;
    }
    this.comentBtn = true;
    this.ReviewsService.comentar(item.review.name, cu.nativeElement.value, localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.modificarResenia(resp.data, item, i);
        Swal.fire('Correcto', 'Comentario enviado', 'success');
      }
      this.comentBtn = false;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
      this.comentBtn = false;
    });
  }
  modificarResenia(resenia: any, item: any, i: number) {
    let review = this.reviews.find((c: any) => c.review.name === item.review.name);
    review.review.reviewReply = resenia;
    this.respuestaAct('', i);
  }
  respuestaAct(resp, iCombo) {
    const cu: ElementRef = this.cuadro.find((c: ElementRef) => c.nativeElement.attributes.id.nodeValue === `comentario-${iCombo}`);
    cu.nativeElement.value = resp;
  }
  VerMAsResenias() {
    if (localStorage.getItem('scroll') !== '0') {
      return;
    }
    localStorage.setItem('scroll', '1');
    this.loaderVerMas = true

    if (this.loaderOne) {
      this.ReviewsService.obtenerMasreseniasOneLocation(localStorage.getItem('OneLocation'), localStorage.getItem('google_token'), localStorage.getItem('NextpageOneReview')).subscribe((resp: ResponseGeneral) => {
        if (resp.ok) {
          localStorage.setItem('scroll', '0');
          const nuevaLista = [...resp.data.reviews];
          nuevaLista.map((x) => {
            let names = x.name.split('reviews');
            names = names[0].slice(0, -1);
            x.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === names);
            x.review = x;
            x.start = this.numberStart(x.review.starRating);
            this.reviews.push(x);
          });
          localStorage.setItem('dataOriginal', JSON.stringify(this.reviews));
          if (resp.data.nextPageToken) {
            localStorage.setItem('NextpageOneReview', resp.data.nextPageToken)
          } else {
            localStorage.removeItem('NextpageOneReview');
          }
          this.loaderVerMas = false;
          if (this.sn) {
            let $event = {
              target: {
                checked: this.sn
              }
            }
            this.filtro('snc', $event);
          }
        }
      }, (err) => {
        if (err.error.msg === 'Unauthorized') {
          this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
            this.VerMAsResenias();
          })
        }
      })

    } else {
      let locations = [];
      this.location.map((r) => {
        locations.push(r.name);
      })
      this.ReviewsService.obtenerMasResenias(locations, localStorage.getItem('locationAutosur'), localStorage.getItem('google_token'), localStorage.getItem('nextPageReview')).subscribe((resp: ResponseGeneral) => {
        if (resp.ok) {
          localStorage.setItem('scroll', '0');
          const nuevaLista = [...resp.data.locationReviews];
          nuevaLista.map((x) => {
            x.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === x.name);
            x.start = this.numberStart(x.review.starRating);
            this.reviews.push(x);
          });
          localStorage.setItem('dataOriginal', JSON.stringify(this.reviews));
          if (resp.data.nextPageToken) {
            localStorage.setItem('nextPageReview', resp.data.nextPageToken);
          } else {
            localStorage.removeItem('nextPageReview');
          }
          this.loaderVerMas = false;
          if (this.sn) {
            let $event = {
              target: {
                checked: this.sn
              }
            }
            this.filtro('snc', $event);
          }
        }
      }, (err) => {
        if (err.error.msg === 'Unauthorized') {
          this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
            this.VerMAsResenias();
          })
        }
      })
    }
  }
  reseniasOne($event: string) {
    this.todosReview = true;
    this.loader = false;
    if ($event === 'todos') {
      this.obtenerResenias();
      return
    }
    const location = $event.split(' ');
    const accounts = localStorage.getItem('locationAutosur').split('/');
    this.ReviewsService.obtenerReviewOneLocation(location[1], localStorage.getItem('google_token'), accounts[1]).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.loaderOne = true;
        this.reviews = [...resp.data.reviews];
        this.loader = true;
        localStorage.setItem('NextpageOneReview', resp.data.nextPageToken)
        localStorage.setItem('dataOriginal', JSON.stringify(this.reviews));
        this.reviews.map((x, i) => {
          let names = x.name.split('reviews');
          names = names[0].slice(0, -1);
          if (i === 0) {
            localStorage.setItem('OneLocation', names);
          }
          x.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === names);
          x.review = x;
          x.start = this.numberStart(x.review.starRating);
          return x;
        });
        if (this.sn) {
          let $event = {
            target: {
              checked: this.sn
            }
          }
          this.filtro('snc', $event);
        }
      }
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.reseniasOne($event);
        })
      }
    })
  }

  filtro(filtro: string, $event: any) {
    this.sn = $event.target.checked;
    let ValorTemp = [];
    switch (filtro) {
      case 'snc':
        if ($event.target.checked) {
          this.reviews.map((x) => {
            let existeEntidad = this.showcommentAutoSur(x);
            if (!existeEntidad) {
              ValorTemp.push(x);
            }
          })
          this.reviews = [];
          this.reviews = [...ValorTemp];
          ValorTemp = [];
        } else {
          const copyData = JSON.parse(localStorage.getItem('dataOriginal'));
          this.reviews = [];
          this.reviews = [...copyData];
          let reviewer;
          this.reviews.map((sr, is) => {
            if (!this.existreviewReviewer(sr)) {
               reviewer = {
                reviewer: sr.reviewer,
                reviewReply: sr.reviewReply,
                comment: sr.comment
              }              
              sr.review = reviewer;
            }
            if(reviewer.comment === undefined){
              delete reviewer.comment;
            }
            if(reviewer.reviewReply === undefined){
              delete reviewer.reviewReply;
            }
            if(!sr.hasOwnProperty('sucursal')){
              const suc = localStorage.getItem('OneLocation');
              sr.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === suc);
              sr.start = this.numberStart(sr.starRating);
            }
            return sr;
          })
          if (this.sn) {
            this.reviews.map((x, i) => {
              let names = x.name.split('reviews');
              names = names[0].slice(0, -1);
              if (i === 0) {
                localStorage.setItem('OneLocation', names);
              }
              x.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === names);
              x.review = x;
              x.start = this.numberStart(x.review.starRating);
              return x;
            });
          }
        }
        break;

      default:
        break;
    }
  }
}
