import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(private http: HttpClient) { }

  obtnerResenias(location: any, google_token: string, locationAutosur: string) {
    const url = `${base_url}/reviews/reviews`
    const data = {
      location,
      google_token,
      locationAutosur
    }
    return this.http.post(url, data);
  }

  obtenerMasResenias(location: any, locationAutosur: string, google_token: string, nextPage: string) {
    const url = `${base_url}/reviews/Masreviws`;
    const data = {
      location,
      google_token,
      locationAutosur,
      nextPage
    };
    return this.http.post(url, data);
  }
  obtenerMasreseniasOneLocation(location: string, google_token:string,nextPage:string ) {
    location = escape(location);
    const url = `${base_url}/reviews/MasreviewsOneLocation/${location}/${google_token}/${nextPage}`;
    return this.http.get(url);
  }

  comentar(name: string, comentario: string, google_token: string) {
    const url = `${base_url}/reviews/comment`;
    const data = {
      comentario,
      name,
      google_token
    }
    return this.http.put(url, data)
  }

  obtenerReviewOneLocation(location: string, google_token: string, locationAutosur: string) {
    const url = `${base_url}/reviews/reviewOneLocation/${location}/${google_token}/${locationAutosur}`;
    return this.http.get(url);
  }

}
