import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  permisos: any[] = [
    {
      permiso: 'Dashboard',
      valor: 'D',
      checket: false
    },
    {
      permiso: 'Usuarios',
      valor: 'V',
      checket: false
    },
    {
      permiso: 'Respuestas Rapidas',
      valor: 'RR',
      checket: false
    },
    {
      permiso: 'Reviewa',
      valor: 'Rw',
      checket: false
    },
    {
      permiso: 'Preguntas',
      valor: 'P',
      checket: false
    },
    {
      permiso:'Publicaciones',
      valor:'PUB',
      checket: false
    }
  ];
  constructor(private http: HttpClient) { }

  obtenerPermisos(id_role: number) {
    const url = `${base_url}/permisos/${id_role}`;
    return this.http.get(url);
  }

  crearPermiso(id_role: number, vistas: string){
    const url = `${base_url}/permisos/new`;
    const data = {
      id_role, vistas 
    }
    return this.http.post(url,data);
  }

  eliminarPermiso(id_role:number,vista:string){
    const url = `${base_url}/permisos/deletePermiso/${id_role}/${vista}`;
    return this.http.delete(url);
  }
}
