import { Component, OnInit } from '@angular/core';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import Swal from 'sweetalert2';
import { RolesService } from '../../services/roles.service';
import { RolesInterfaces } from '../../interfaces/roles.interfaces';
import { PermisosService } from '../../services/permisos.service';

@Component({
  selector: 'app-config-roles',
  templateUrl: './config-roles.component.html',
  styleUrls: ['./config-roles.component.css']
})
export class ConfigRolesComponent implements OnInit {
  loader: boolean = false;
  listaRoles: RolesInterfaces[] = [];
  permisos: any[];
  idRole: string = '';

  constructor(private RolesService: RolesService, private PermisosService: PermisosService) {
    this.permisos = PermisosService.permisos;
    localStorage.setItem('permisosOriginales', JSON.stringify(this.permisos));
  }

  ngOnInit(): void {
    this.obtenerRoles();
  }

  obtenerRoles(): void {
    this.loader = false;
    this.RolesService.obtenerRoles().subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.listaRoles = [...resp.data];
      }
      this.loader = true;
    }, (err) => {
      this.loader = true;
      Swal.fire('Error', err.error.msg, 'error')
    })
  }

  obtenerPermisos(id: string) {
    this.permisos = JSON.parse(localStorage.getItem('permisosOriginales'));
    this.idRole = id;
    this.PermisosService.obtenerPermisos(Number(id)).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        const listapermisos = [...resp.data];
        if (resp.total !== 0) {
          listapermisos.map((x) => {
            const cambio = this.permisos.find(p => p.valor === x.vistas);
            cambio.checket = true;
          })
        }
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }

  PermisoChange(checket: boolean, valor: string) {
    if (this.idRole === '') {
      return
    }
    if (checket) {
      this.PermisosService.crearPermiso(Number(this.idRole), valor).subscribe((resp: ResponseGeneral) => {
        if (resp.ok) {
          console.log('Agregado');
        }
      }, (err) => {
        Swal.fire('Error', err.error.msg, 'error')
      })
    } else {
      this.PermisosService.eliminarPermiso(Number(this.idRole), valor).subscribe((resp: ResponseGeneral) => {
        if (resp.ok) {
          console.log(resp.msg);
        }
      }, (err) => {
        Swal.fire('Error', err.errpor.msg, 'error')
      })

    }

  }

}
