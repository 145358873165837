<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img
                            [src]="urlImg" alt="user" /><span class="hide-menu">{{nombre}}
                        </span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLink="account-settings">Account Setting</a></li>
                        <li><a routerLink="/login">Logout</a></li>
                        <!--<li><a routerLink="configNegocio">Configuracion Negocio</a> </li> -->
                        <li *ngIf="role === '1'"><a routerLink="configuracionRoles">Configuracion Roles</a> </li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li>

                <li *ngFor="let item of menuItems">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <i [class]="item.icono"></i>
                        <span class="hide-menu">
                            {{ item.titulo }}
                        </span>
                    </a>

                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let subMenuItem of item.submenu">
                            <a [routerLink]="subMenuItem.url" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact:true}"> {{ subMenuItem.titulo }} </a>
                        </li>
                    </ul>
                </li>

            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->