import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { instructoresModel } from '../interfaces/instructores.interfaces';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class InstructoresService {

  constructor(private http: HttpClient) { }

  ontenerInstructores(numCliente: number){
    const url = `${base_url}/instructor/${numCliente}`;
    return this.http.get(url);
  }

  crearInstructor(data: instructoresModel){
    const url = `${base_url}/instructor/new`;
    return this.http.post(url, data);
  }

  updateInstructor(data:instructoresModel){
    const url = `${base_url}/instructor/edit`;
    return this.http.put(url,data);
  }
  deleteInstructor(idInstructor:number){
    const url = `${base_url}/instructor/dele/${idInstructor}`;
    return this.http.delete(url);
  }
}
