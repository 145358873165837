<form class="form-horizontal form-material" [formGroup]="congForm" (submit)="submitConfig()" >
    <div class="form-group ">
        <div class="col-xs-12">
            <label class="form-label">
                Nombre del negocio
            </label>
            <input class="form-control" type="text" formControlName="nombre">
        </div>
        <span ErrorMsg [valido]="!campoNoValido('nombre')" class="text-danger"
            [mensaje]="mensajeError"></span>
    </div>
    <div class="form-group ">
        <div class="col-xs-12">
            <label class="form-label">
                Dirección
            </label>
            <input class="form-control" type="text" formControlName="direccion">
        </div>
        <span ErrorMsg [valido]="!campoNoValido('direccion')" class="text-danger"
            [mensaje]="mensajeError"></span>
    </div>
    <input type="file" (change)="cambiarImagen($event.target.files[0])">
    <div class="text-center">
        <!-- <img *ngIf="!imgTemp" [src]="usuario.imagenUrl" class="img-avatar"> -->
        <img *ngIf="imgTemp" [src]="imgTemp" class="img-avatar">
    </div>
    <br>
    <div class="col-md-12">
        <div class="d-flex justify-content-center">
            <div class="col-md-4">
                <button type="submit" class="btn btn-success btn-block waves-effect waves-light m-r-10"
                    [disabled]="!congForm.valid">
                    <i class="fa fa-save"></i>
                    Guardar Cambios
                </button>
            </div>
        </div>
    </div>
</form>