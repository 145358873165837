import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { AuthService } from '../../services/auth.service';
import { RespuestasService } from '../../services/respuestas.service';
import Swal from 'sweetalert2';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { PreguntasService } from '../../services/preguntas.service';
import { respuestas } from 'src/app/interfaces/respuestas.interfaces';

@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class PreguntasComponent implements OnInit {
  @ViewChildren('cuadro') cuadro: QueryList<ElementRef>
  loader: boolean = false;
  sn: boolean = false;
  preguntas = [];
  location = [];
  loaderOne: boolean = false;
  valor: string = '';
  valorLocation: string = '';
  loaderVerMas: boolean = false;
  mostrarMensaje: boolean = false;
  comentBtn: boolean = false;
  respuestas: respuestas[] = [];

  constructor(
    private LocationService: LocationService,
    private AuthService: AuthService,
    private RespuestasService: RespuestasService,
    private PreguntasService: PreguntasService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.obtenerLocation();
    await this.obtenerRespuestas();
    localStorage.setItem('scrollPage', '0');
  }
  async obtenerRespuestas(): Promise<void> {
    this.RespuestasService.ObtenerListadoRespuestas().subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.respuestas = [...resp.data];

      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }



  async obtenerLocation(): Promise<void> {
    this.loader = false;
    this.LocationService.obtenerLocation(localStorage.getItem('locationAutosur'), localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.location = [...resp.data];

        this.loader = true;
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  obtenerPreguntas(location: string): void {
    this.sn = false;
    this.loader = false;
    this.PreguntasService.obtenerPreguntas(location, localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.loader = true;
        this.preguntas = [...resp.data.questions];
        this.mostrarMensaje = true;
        const nameLocation = this.location.find((c) => c.name === this.valor);
        this.valorLocation = nameLocation.title;
        localStorage.setItem('dataOriginalPreguntas', JSON.stringify(this.preguntas));

        if (resp.data.nextPageToken) {
          localStorage.setItem('nextPageTokenPreguntas', resp.data.nextPageToken);
        } else {
          localStorage.removeItem('nextPageTokenPreguntas');
        }
      }
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error')
    })
  }

  modificarRespuestPregunta(pregunta: any, item: any, i: number) {
    let preguntas = this.preguntas.find((c: any) => c.name === item.name);
    const existeEntidad = this.showcommentAutoSur(preguntas);
    if (!existeEntidad) {
      preguntas.topAnswers = [{
        text: pregunta.text
      }]
    } else {
      preguntas.topAnswers[0].text = pregunta.text;
    }
    this.respuestaAct('', i);
  }

  showcommentAutoSur(item: any) {
    let ok = false;
    const existResponse = item.hasOwnProperty('topAnswers');
    if (existResponse) {
      ok = true;
    }
    return ok;
  }

  verMasPreguntas() {
    if (localStorage.getItem('scrollPage') !== '0') {
      this.loaderVerMas = false;
      return;
    }
    if(localStorage.getItem('nextPageTokenPreguntas') === null){
      this.loaderVerMas = false;
      return;
    }
    localStorage.setItem('scrollPage', '1');
    this.loaderVerMas = true;

    
    this.PreguntasService.obtenerMasPreguntas(this.valor, localStorage.getItem('nextPageTokenPreguntas'), localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if(resp.ok){
        localStorage.setItem('scrollPage', '0');
        const nueva = [...resp.data.questions];
        nueva.map((x) => {
          this.preguntas.push(x)
        })
       
        if (resp.data.nextPageToken) {
          localStorage.setItem('nextPageTokenPreguntas', resp.data.nextPageToken);
        } else {
          localStorage.removeItem('nextPageTokenPreguntas');

        }
        this.loaderVerMas = false;
      }
    }, (err) => {
      if (err.error.msg === 'Unauthorized') {
        this.AuthService.refresTokenGoogle().subscribe((resp: ResponseGeneral) => {
          this.verMasPreguntas();
        })
      }
    })
  }
  filtro(filtro: string, $event: any) {
    this.sn = $event.target.checked;
    let ValorTemp = [];
    switch (filtro) {
      case 'snc':
        if ($event.target.checked) {
          this.preguntas.map((p) => {
            let existeentidad = this.showcommentAutoSur(p);
            if (!existeentidad) {
              ValorTemp.push(p);
            }
          });
          this.preguntas = [];
          this.preguntas = [...ValorTemp];
          ValorTemp = [];
        } else {
          const copyData = JSON.parse(localStorage.getItem('dataOriginalPreguntas'));
          this.preguntas = [];
          this.preguntas = [...copyData];
          // if (this.sn) {
          //   this.preguntas.map((x, i) => {
          //     let names = x.name.split('reviews');
          //     names = names[0].slice(0, -1);
          //     if (i === 0) {
          //       localStorage.setItem('OneLocation', names);
          //     }
          //     x.sucursal = this.location.find(s => `${localStorage.getItem('locationAutosur')}/${s.name}` === names);
          //     x.review = x;
          //     return x;
          //   });
          // }
        }
        break;
    }
  }
  respuestaAct(resp, iCombo) {
    const cu: ElementRef = this.cuadro.find((c: ElementRef) => c.nativeElement.attributes.id.nodeValue === `comentario-${iCombo}`);
    cu.nativeElement.value = resp;
  }
  comentarPregunta(item: any, i: number) {
    const cu: ElementRef = this.cuadro.find((c: ElementRef) => c.nativeElement.attributes.id.nodeValue === `comentario-${i}`);

    if (cu.nativeElement.value === '') {
      Swal.fire('Error', 'no puedes enviar un comentaio vacio', 'error');
      return;
    }
    const aswer = {
      answer: {
        text: cu.nativeElement.value
      }
    }
    this.comentBtn = true;
    this.PreguntasService.comentar(item.name, aswer, localStorage.getItem('google_token')).subscribe((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.modificarRespuestPregunta(resp.data, item, i);
        Swal.fire('Correcto', 'Comentario enviado', 'success');
      }
      this.comentBtn = false;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
      this.comentBtn = false;
    });
  }
}
