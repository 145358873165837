import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const base_url = environment.base_url
@Injectable({
  providedIn: 'root'
})
export class PublicacionesService {

  constructor(private http: HttpClient) { }

  getListadoPublicaciones(urlLocation: string, google_token: string) {
    const urlLocationsScape = escape(urlLocation);
    const data = {
      locations: urlLocationsScape,
      google_token: google_token,
    }
    const url = `${base_url}/publicaciones/`;
    return this.http.post(url, data);
  }

  crearPublicacion(data: any, media: File, locationAutosur, google_token, location) {
    const url = `${base_url}/publicaciones/addpublicacion`;
    const formData = new FormData();
    formData.append('imagen', media)
    const dataPublicacion = {
      summary: data.summary,
      topicType: data.topicType,
      callToAction: data.callToAction,
      fstart: data.fstart,
      fend: data.fend,
      urlAction: data.urlAction,
      google_token: google_token,
      locationAutosur: locationAutosur,
      location: location
    }
    formData.append('body', JSON.stringify(dataPublicacion))
    return this.http.post(url, formData);
  }
  deletepublicacion(name: string, google_token: string) {
    const url = `${base_url}/publicaciones/deletepublicacion/`;
    const data = {
      name,
      google_token
    }
    return this.http.post(url, data);
  }
}
