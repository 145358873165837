<div class="row">
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="m-r-20 align-self-center"><img src="./assets/images/icon/staff.png" alt="Income"></div>
                    <div class="align-self-center">
                        <h3 class="text-muted m-t-10 m-b-0">Clientes</h3>
                        <h2 class="m-t-0">{{clientes}}</h2></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card">
            <div class="card-body">
                <div class="d-flex">
                    <div class="m-r-20 align-self-center"><img src="./assets/images/icon/expense.png" alt="Income"></div>
                    <div class="align-self-center">
                        <h3 class="text-muted m-t-10 m-b-0">Respuestas Rapidas</h3>
                        <h2 class="m-t-0">{{respuestas}}</h2></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-setings-img></app-setings-img>