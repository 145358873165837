import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private http:HttpClient) { }

  getNotificacion(){
    const url = `${base_url}/notificaciones/`;
    return this.http.get(url);
  }
}
