import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any[] = [
    {
      titulo: 'Dashboard',
      icono: 'mdi mdi-gauge',
      submenu: [
        { titulo: 'Inicio', url: '/' },
      ],
      valor: 'D'
    },
    {
      titulo: 'Usuarios',
      icono: 'mdi mdi-account',
      submenu: [
        {titulo: 'Usuarios', url: 'usuarios'}
      ],
      valor: 'V'
    },
    {
      titulo: 'Respuestas Rapidas',
      icono: 'mdi mdi-comment-text-outline',
      submenu: [
        { titulo: 'Respuestas Rapidas', url: 'respuestas' },
      ],
      valor: 'RR'
    },
    {
      titulo: 'Reviews',
      icono: 'mdi mdi-contacts',
      submenu: [
        {titulo: 'Listado de Reviews', url: 'reviews'},
      ],
      valor: 'Rw'
    },
    {
      titulo: 'Preguntas',
      icono: 'mdi mdi-comment-question-outline',
      submenu: [
        {titulo: 'Preguntas', url: 'preguntas'}
      ],
      valor:'P'
    },
    {
      titulo: 'Publicaciones',
      icono: 'mdi mdi-bookmark-plus',
      submenu: [
        {titulo: 'Publicaciones', url: 'publicaciones'}
      ],
      valor:'PUB'
    },
  ];

  constructor(private http:HttpClient) { }

  obtenerMenu(){
    const menu = JSON.parse(localStorage.getItem('menu'));
    const menuFinal = []
    menu.map((m) => {
      const busqueda = this.menu.find((x) => x.valor === m.vistas);
      menuFinal.push(busqueda);
    });

    return menuFinal;
  }
}
