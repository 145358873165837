import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RjxComponent } from './rjx/rjx.component';
import { AuthGuard } from '../guard/auth.guard';
import { RegisterClienteComponent } from './register-cliente/register-cliente.component';
import { InscripcionesComponent } from './inscripciones/inscripciones.component';
import { MensualidadesComponent } from './mensualidades/mensualidades.component';
import { InscripcionesAvencerComponent } from './inscripciones-avencer/inscripciones-avencer.component';
import { InstructoresComponent } from './instructores/instructores.component';
import { ConfigNegocioComponent } from './config-negocio/config-negocio.component';
import { SessionGuard } from '../guard/session.guard';
import { QrComponent } from './qr/qr.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { RespuestasComponent } from './respuestas/respuestas.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { ConfigRolesComponent } from './config-roles/config-roles.component';
import { AnaliticsComponent } from './analitics/analitics.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';

const routes: Routes = [
    {
        path: 'dashboard',
        component: PagesComponent,
        canActivate: [AuthGuard, SessionGuard],
        children: [
            { path: '', component: DashboardComponent, data: { titulo: 'Dashboard' } },
            { path: 'progress', component: ProgressComponent, data: { titulo: 'Progress' } },
            { path: 'grafica1', component: Grafica1Component, data: { titulo: 'Grafica' } },
            { path: 'account-settings', component: AccountSettingsComponent, data: { titulo: 'Ajustes' } },
            { path: 'promesas', component: PromesasComponent, data: { titulo: 'Promesas' } },
            { path: 'rjx', component: RjxComponent, data: { titulo: 'Rjx' } },
            { path: 'clientes', component: RegisterClienteComponent, data: { titulo: 'Clientes' } },
            { path: 'inscripciones', component: InscripcionesComponent, data: { titulo: 'Inscripciones' } },
            { path: 'mensualidades', component: MensualidadesComponent, data: { titulo: 'Mensualidades' } },
            { path: 'InscripcionesAvencerComponent', component: InscripcionesAvencerComponent, data: { titulo: 'Inscripciones a vencer' } },
            { path: 'instructores', component: InstructoresComponent, data: { titulo: 'Instructores' } },
            { path: 'configNegocio', component: ConfigNegocioComponent, data: { titulo: 'Configuracion Negocio' } },
            { path: 'usuarios', component: UsuarioComponent, data: { titulo: 'Usuarios' } },
            { path: 'respuestas', component: RespuestasComponent, data: { titulo: 'Respuestas Rapidas' } },
            { path: 'reviews', component: ReviewsComponent, data: { titulo: 'Reviews' } },
            { path: 'preguntas', component: PreguntasComponent, data: { titulo: 'Preguntas' } },
            { path: 'configuracionRoles', component: ConfigRolesComponent, data: { titulo: 'Configuracióm Roles' } },
            { path: 'analitics', component: AnaliticsComponent, data: { titulo: 'Analytics' } },
            { path: 'publicaciones', component: PublicacionesComponent, data: { titulo: 'Publicaciones' } }

        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }


