import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promesas',
  templateUrl: './promesas.component.html',
  styleUrls: ['./promesas.component.css']
})
export class PromesasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    this.getUsusario().then(usuarios => {
      console.log( usuarios);
      
    });
    // const promesa = new Promise((resolve) => {
    //   resolve('hola mundo');
    // });

    // promesa.then(() => {
    //   console.log('termine');
      
    // })

    // console.log('fin');

  }

  getUsusario(){
    return  new Promise((resolve) => {
      fetch('https://reqres.in/api/users')
      .then(resp => resp.json())
      .then(body => resolve(body.data)
      )
    });
    
  }
}
