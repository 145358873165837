<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="col-md-12 d-flex justify-content-center">
        <div class="login-box card">
            <div class="card-body">
                <div class="d-flex justify-content-center">
                    <h3>REGISTRO</h3>
                </div>
                <form class="form-horizontal form-material" id="loginform" [formGroup]="registerForm"
                    (ngSubmit)="submitForms();">
                    <div class="form-group">
                        <div class="col-xs-12">
                            <label class="form-label">
                                Nombre
                            </label>
                            <input class="form-control" type="text" required="" formControlName="nombre"
                                placeholder="Nombre">
                        </div>
                        <span ErrorMsg [valido]="!campoNoValido('nombre')" class="text-danger"
                            [mensaje]="mensajeError"></span>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <label class="form-label">
                                Correo
                            </label>
                            <input class="form-control" type="text" required="" placeholder="Correo"
                                formControlName="correo">
                        </div>
                        <span ErrorMsg [valido]="!campoNoValido('correo')" class="text-danger"
                            [mensaje]="mensajeError"></span>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <label class="form-label">
                                Dirección
                            </label>
                            <input class="form-control" type="text" required="" placeholder="Direccion"
                                formControlName="direccion">
                        </div>
                        <span ErrorMsg [valido]="!campoNoValido('direccion')" class="text-danger"
                            [mensaje]="mensajeError"></span>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group ">
                                    <div class="col-xs-12">
                                        <label class="form-label">
                                            Edad
                                        </label>
                                        <input class="form-control" type="text" required="" placeholder="Edad"
                                            formControlName="edad">
                                    </div>
                                    <span ErrorMsg [valido]="!campoNoValido('edad')" class="text-danger"
                                        [mensaje]="mensajeError"></span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group ">
                                    <div class="col-xs-12">
                                        <label class="form-label">
                                            Teléfono
                                        </label>
                                        <input class="form-control" type="text" required="" placeholder="Teléfono"
                                            formControlName="telefono">
                                    </div>
                                    <span ErrorMsg [valido]="!campoNoValido('telefono')" class="text-danger"
                                        [mensaje]="mensajeError"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <label class="form-label">
                                DNI
                            </label>
                            <input class="form-control" type="text" required="" placeholder="DNI" formControlName="dni">
                        </div>
                        <span ErrorMsg [valido]="!campoNoValido('dni')" class="text-danger"
                            [mensaje]="mensajeError"></span>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <label class="label-form">
                                Contraseña
                            </label>
                            <input class="form-control" type="password" required="" placeholder="Password"
                                formControlName="password">
                        </div>
                    </div>
                    <div class="form-group text-center p-b-20">
                        <div class="col-xs-12">
                            <button
                                class="btn btn-info btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light"
                                type="submit" [disabled]="!registerForm.valid">Registrar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->