import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class AnalitycsService {

  constructor(private http: HttpClient) { }

  analitycs(locations: any, google_token: string, locationAutosur: string | any, f1: string, f2: string) {
    locations = JSON.stringify(locations);
    locationAutosur = locationAutosur.split('/');
    locationAutosur = locationAutosur[1];
    const url = `${base_url}/analitycs/`;
    const data = {
      locations,
      google_token,
      locationAutosur,
      f1,
      f2
    }
    return this.http.post(url, data);
  }
  obtenerStartsLocation(location: any, locationAutosur: string, google_token: string) {
    const url = `${base_url}/analitycs/startReview`;
    const data = {
      location,
      locationAutosur,
      google_token
    }
    return this.http.post(url, data);
  }
}
