import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const base_url = environment.base_url;
@Injectable({
    providedIn: 'root'
})
export class PreguntasService {

    constructor(private http: HttpClient) { }

    obtenerPreguntas(location: string, google_token: string) {
        const url = `${base_url}/preguntas/${location}/${google_token}`;
        return this.http.get(url);
    }

    obtenerMasPreguntas(location:string, nextPage:string, google_token:string){
        const url = `${base_url}/preguntas/masPreguntas/${location}/${nextPage}/${google_token}`;
        return this.http.get(url);
    }

    comentar(pregunta: string, aswer: any, google_token: string) {
        const url = `${base_url}/preguntas/contestarPregunta`;
        const data = {
            pregunta,
            aswer,
            google_token
        };
        return this.http.post(url, data);
    }
}
