import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { MensualidadesService } from '../../services/mensualidades.service';
import { Mensualidades } from '../../interfaces/mensualidades.interfaces';
import { ResponseGeneral } from '../../interfaces/response.interfaces';

@Component({
  selector: 'app-clinete-inscribir-modal',
  templateUrl: './clinete-inscribir-modal.component.html',
  styleUrls: ['./clinete-inscribir-modal.component.css']
})
export class ClineteInscribirModalComponent implements OnInit {

  @Output() valorSalida : EventEmitter<boolean> = new EventEmitter();
  valor: number = 0;
  listaMensualidades:Mensualidades[] = [];
  constructor(public ModalService:ModalService, private MensualidadesService: MensualidadesService) { }

  ngOnInit(): void {
    this.obtenerMensualidades();
  }
  get idMensualidad(){
    return this.ModalService.idMensualidad
  }

  obtenerMensualidades(){
    this.MensualidadesService.obtenerListadoMensualidades().subscribe((resp:ResponseGeneral) => {
      const listaMensualidades = resp.data;
      this.listaMensualidades = [...listaMensualidades];     
    });
  }

  cerrarModal(): void {
    this.ModalService.cerrarModalMensualidad();

    if(this.idMensualidad === 0){
      this.valor = 0;
      this.ModalService.setIdMensualidad(this.valor);
      this.valorSalida.emit(false);
    }
    this.valorSalida.emit(true);
  }

  aceptar(): void {
    this.ModalService.setIdMensualidad(Number(this.valor));
    this.cerrarModal();
  }

}
