import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { RegisterInterfaces } from '../interfaces/register.interfaces';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { responsesAdmin } from '../interfaces/response.interfaces';


const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  crearUsuario (formData: RegisterInterfaces) {
    const url = `${base_url}/auth/admin`;
    return this.http.post(url,formData).pipe(
      tap((resp:responsesAdmin) => {
        localStorage.setItem('token', resp.token);
      })
    )
  }
}
