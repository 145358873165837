<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="alert  text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="accionPagina === 'Listar' && loader">
    <div class="col-12">

        <div class="card">
            <div class="d-flex justify-content-end mr-2 mt-2">
                <button type="button" class="btn btn-circle btn-md btn-primary" data-placement="top"
                    title="Agregar nuevo cliente" (click)="nuevoUsuario();"><i class="fa fa-plus"></i></button>
            </div>
            <div class="card-body">
                <h4 class="card-title">Usuarios</h4>
                <h6 class="card-subtitle">Usuarios registrados</h6>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>correo</th>
                                <th>role</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lista of listaUsuarios | paginate: {itemsPerPage: 3, currentPage: page}">
                                <td>{{lista.nombre}}</td>
                                <td>{{lista.correo}}</td>
                                <td>{{lista.role}}</td>
                                <td class="text-nowrap">
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Edit"
                                        (click)="actualizarUsuario(lista)"> <i
                                            class="fa fa-pencil text-inverse m-r-10"></i> </a>
                                    <a data-toggle="tooltip" data-original-title="Borrar"> <i
                                            class="fa fa-close text-danger" (click)="deleteCliente(lista)"></i> </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="page = $event" nextLabel="Siguiente" previousLabel="Anterior" ></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="accionPagina !== 'Listar'">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3>{{accionPagina === "Crear" ? 'Crear nuevo cliente':'Editar cliente'}}</h3>
            </div>
            <div class="card-body">

                <form class="form-horizontal form-material" [formGroup]="userForm" (submit)="(submitUsuario())">

                    <div class="" *ngIf="accionPagina === 'Crear'">

                    </div>
                    <div class="form-group">
                        <label>Nombre completo</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('name')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Correo</label>
                        <input type="email" class="form-control" formControlName="email">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('email')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" formControlName="password">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('password')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Role</label>
                        <select class="form-control" formControlName="role">
                            <option [ngValue]="null" disabled>-- SELECCIONA UNA OPCIÓN--</option>
                            <option [ngValue]="1">ADMINISTRADOR</option>
                            <option [ngValue]="2">ASESOR</option>
                        </select>
                    </div>

                    <div class="row">
                        <button class="btn btn-secondary mr-2" [disabled]="!userForm.valid"> Guardar </button>
                        <button class="btn btn-danger" (click)="cancelar();"> Cancelar </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>