import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import {NgChartsModule } from 'ng2-charts';

import { IncrementadorComponent } from './incrementador/incrementador.component';
import { DonaComponent } from './dona/dona.component';
import { ModalesComponent } from './modales/modales.component';
import { ClineteInscribirModalComponent } from './clinete-inscribir-modal/clinete-inscribir-modal.component';
import { SetingsImgComponent } from './setings-img/setings-img.component';
import { ErrorMsgDirective } from '../shared/directivas/error-msg.directive';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    IncrementadorComponent,
    DonaComponent,
    ModalesComponent,
    ClineteInscribirModalComponent,
    SetingsImgComponent,    
  ],
  exports: [
    IncrementadorComponent,
    DonaComponent,
    ModalesComponent,
    ClineteInscribirModalComponent,
    SetingsImgComponent,    
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgChartsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class ComponentsModule { }
