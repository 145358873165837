import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SetingsImgService } from '../../services/setings-img.service';
import { ResponseGeneral } from '../../interfaces/response.interfaces';
import { SetingsImg } from '../../interfaces/setings.interfaces';
import { UsuariosService } from '../../services/usuarios.service';
import Swal from 'sweetalert2';
import { SettingsService } from '../../services/settings.service';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-config-negocio',
  templateUrl: './config-negocio.component.html',
  styleUrls: ['./config-negocio.component.css']
})
export class ConfigNegocioComponent implements OnInit {

  public imagenSubir: File;
  public imgTemp: any = null;

  mensajeError: string;
  congForm = this.fb.group({
    id: [null],
    nombre: [null, [Validators.required]],
    direccion: [null, [Validators.required]]
  })

  constructor(private fb: FormBuilder, private SetingsImgService: SetingsImgService, private UsuariosService: UsuariosService, private DashboardService:DashboardService) { }

  ngOnInit(): void {
    this.obtenerNegocio();
  }

  private obtenerNegocio() {
    this.DashboardService.getConfigNegocio(Number(this.UsuariosService.idUsuario)).subscribe((resp: ResponseGeneral) => {
      const negocio: SetingsImg = resp.data[0];
      this.congForm.get('id')?.setValue(negocio.id);
      this.congForm.get('nombre')?.setValue(negocio.nombre);
      this.congForm.get('direccion')?.setValue(negocio.direccion);
      this.imgTemp = this.UsuariosService.imgNegocioFinal(negocio.img);
    });
  }
  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.congForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }
  cambiarImagen(file: File) {
    this.imagenSubir = file;
    if (!file) {
      return this.imgTemp = null;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.imgTemp = reader.result;
    }
    console.log(this.imagenSubir);

  }

  submitConfig() {

    Swal.fire({
      title: `Estas Seguro ?`,
      text: `Se actualizara la información`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    }).then((res) => {
      if (res.isConfirmed) {
        this.SetingsImgService.updateConfig(this.congForm.value).subscribe((resp: ResponseGeneral) => {
          if (this.imagenSubir !== undefined) {
            this.subirImagen();
          } else {
            Swal.fire('Correcto', resp.msg, 'success');
          }
        }, (error) => {
          Swal.fire('Error', error.msg, 'error');
        })
      }
    });
  }

  async subirImagen(): Promise<void> {
    this.SetingsImgService.actualizarImagen(this.imagenSubir, "negocio", this.congForm.get('id')?.value).then((resp: ResponseGeneral) => {
      if (resp.ok) {
        this.obtenerNegocio();
        Swal.fire('Correcto', 'configuracion Actualizada', 'success');
      }
    }, (err) => {
      console.log('entre');
      console.log(err);
      
      Swal.fire('Error', err.error.msg, 'error');
    })
  }
}
