<!-- <div class="row">
    <div class="col-6">
        <app-dona title="Ventas"></app-dona>
    </div>
</div>

-->




<!-- <div mwlResizable [enableGhostResize]="true" [resizeSnapGrid]="{ left: 1, right: 1 }"
    [resizeEdges]="{ bottom: true, right: true, top: true, left: true }" (resizeEnd)="onResizeEnd3($event)"
    class="rectanglePadre" [ngStyle]="padreStyle">

    <div class="row">
        <div mwlResizeHandle [resizeEdges]="{ top: true, bottom: true ,left: true, right: true}"><i
                class="fa fa-arrows resize-handle-top" aria-hidden="true"></i></div>
        <div mwlResizable [enableGhostResize]="true" [resizeSnapGrid]="{ left: 1, right: 1 }"
            [resizeEdges]="{ bottom: true, right: true, top: true, left: true }" (resizeEnd)="onResizeEnd($event)"
            class="rectangle" [ngStyle]="style">
            <div mwlResizeHandle [resizeEdges]="{ top: true, bottom: true ,left: true, right: true}"><i
                    class="fa fa-arrows resize-handle-top" aria-hidden="true"></i></div>
            <google-chart [type]="chartData.type" [data]="chartData.data" [columns]="chartData.columnNames"
                [width]="chartData.width" [height]="chartData.height">
            </google-chart>
        </div>
    
        <div mwlResizable [enableGhostResize]="true" [resizeSnapGrid]="{ left: 1, right: 1 }"
            [resizeEdges]="{ bottom: true, right: true, top: true, left: true }" (resizeEnd)="onResizeEnd2($event)"
            class="rectangle" [ngStyle]="style2">
            <div mwlResizeHandle [resizeEdges]="{ top: true, bottom: true ,left: true, right: true}"><i
                    class="fa fa-arrows resize-handle-top" aria-hidden="true"></i></div>
            <google-chart [type]="chartData2.type" [data]="chartData2.data" [columns]="chartData2.columnNames"
                [width]="chartData2.width" [height]="chartData2.height">
            </google-chart>
        </div>
    </div>

</div> -->

<div class="container">
    <div class="div1" appResizable>
        <p> Hello I am the first of three resizable components. Just drag the grey bar to my right.
    </div>
    <div class="div2" [resizableMinWidth]=30 appResizable>
        <h2> I am stuck in the middle. </h2>
    </div>
    <div class="div3" appResizable>
        <p> I am the last one !
        <p>

    </div>
</div>