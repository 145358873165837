<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="accionPagina === 'Listar' && loader">
    <div class="col-12">
        <div class="card">
            <div class="d-flex justify-content-end mr-2 mt-2">
                <button type="button" class="btn btn-circle btn-md btn-primary" data-placement="top"
                    title="Crear nueva mensualidad" (click)="nuevaInscripcion();"><i class="fa fa-plus"></i></button>
            </div>
            <div class="card-body">
                <h4 class="card-title">Lista de Inscripciones</h4>

                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre Cliente</th>
                                <th>Mensualidades</th>
                                <th>Estatus</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lista of listaInscripcion">
                                <td>{{lista.nombreCliente}}</td>
                                <td>{{lista.nombreMensualidad}}</td>
                                <td>{{lista.status}}</td>
                                <td class="text-nowrap">
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Edit"
                                        (click)="actualizarInscripcion(lista)"> <i
                                            class="fa fa-pencil text-inverse m-r-10"></i> </a>
                                    <a data-toggle="tooltip" data-original-title="Borrar" class="pointer"> <i
                                            class="fa fa-close text-danger" (click)="deleteInscripcion(lista)"></i> </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-primary mr-2">siguiente</button>
                    <button class="btn btn-primary">anterior</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="accionPagina !== 'Listar' && loader">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body p-3">
                <div *ngIf="acPasos == 'Usuarios'">
                    <div class="row">
                        <span class="fa-stack fa-lg">
                            <i class="fa fa-circle fa-stack-2x fa-2x text-primary"></i>
                            <i class="fa fa-user fa-stack-1x fa-inverse"></i>
                        </span>
                        <h3>Buscar Cliente </h3> <small class="mt-1 ml-2">(NOMBRE COMPLETO DEL CLIENTE)</small>
                    </div>
                    <div class="d-flex justify-content-center">
                        <input class="form-control" [(ngModel)]="valorBusqueda">
                    </div>
                    <!-- <div class="d-flex justify-content-center mt-3">
                        <button type="button" class="btn btn-primary" (click)="siguientePaso()">siguiente <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                    </div> -->
                    <div class="d-flex justify-content-center mt-3">
                        <button type="button" class="btn btn-primary mr-3" (click)="buscarCliente()" [disabled]="valorBusqueda.length === 0">Buscar <i
                                class="fa fa-search" aria-hidden="true"></i></button>
                        <button type="button" class="btn btn-danger" (click)="cancelarInscripcion()">Regresar <i
                                class="fa fa-undo" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Usuarios</h4>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Fecha Final Inscripción</th>
                                        <th class="text-nowrap">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let item of listaClientes">
                                    <tr>
                                        <td>{{item.Nombre}}</td>
                                        <td>{{item.fechaFinalInscripcion !== 'no-date'?item.fechaFinalInscripcion: ''}}
                                        </td>
                                        <td><button type="button" (click)="inscripcion(item.reincribir, item)"
                                                *ngIf="item.boton; else vigente"
                                                class="btn btn-outline-primary">{{item.reincribir ? 'Reinscribir':
                                                'Inscripcion'}}</button></td>
                                        <ng-template #vigente><span class="badge badge-secondary">Inscrito</span>
                                            </ng-template>

                                    </tr>
                                </tbody>
                            </table>

                            <button class="btn btn-primary mr-2">siguiente</button>

                            <button class="btn btn-primary">anterio</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-clinete-inscribir-modal (valorSalida)="cambioValorMensualidad($event)"></app-clinete-inscribir-modal>
