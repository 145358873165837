import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { responsesAdmin } from 'src/app/interfaces/response.interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  cargando: boolean = false;

  loginForm = this.fb.group({
    correo: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(1)]]
  })



  constructor(private router: Router, private fb: FormBuilder, private AuthService: AuthService) { }

  ngOnInit(): void {
  }

  login() {
    this.router.navigateByUrl('/');
  }

  submitLogin(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.cargando = true;
    this.AuthService.auth(this.loginForm.value).subscribe((resp: responsesAdmin) => {
      if (resp.ok) {
        this.router.navigateByUrl('/');
      }
    }, (err) => {
      this.cargando = false;
      Swal.fire('Error', err.error.msg, 'error')
    })
  }

}
