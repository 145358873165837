import { Component, OnInit } from '@angular/core';
import { Acciones, Pasos } from '../../module/acciones.module';
import { Inscripcion } from '../../interfaces/inscripcion.interfaces';
import { InscripcionService } from '../../services/inscripcion.service';
import { ResponseGeneral } from 'src/app/interfaces/response.interfaces';
import { UsuariosService } from '../../services/usuarios.service';
import { Usuarios, Clientes } from '../../interfaces/usuario.interfaces';
import { FormBuilder, Validators } from '@angular/forms';
import { MensualidadesService } from '../../services/mensualidades.service';
import { Mensualidades } from '../../interfaces/mensualidades.interfaces';
import Swal from 'sweetalert2';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-inscripciones',
  templateUrl: './inscripciones.component.html',
  styleUrls: ['./inscripciones.component.css']
})
export class InscripcionesComponent implements OnInit {
  accionPagina = Acciones.Listar;
  acPasos = Pasos.Usuario;
  modal: boolean;
  valorBusqueda: string = '';
  tipoInscripcion: string = '';
  listaInscripcion: Inscripcion[] = [];
  loader: boolean = false;
  listaUsuarios: Usuarios[] = [];
  listaMensualidades: Mensualidades[] = [];
  selectedUsuario: number = 0;
  selectedMensualidad: number = 0;
  idUsuario: string = '';
  inscripcionForm = this.fb.group({
    id: [null],
    id_usuario: [null, [Validators.required]],
    id_mensualidad: [null, [Validators.required]],
  });
  mensajeError: string;
  listaClientes: Clientes[] = [];
  constructor(private InscripcionService: InscripcionService, private UsuariosService: UsuariosService,
    private fb: FormBuilder, private MensualidadesService: MensualidadesService, private ModalService: ModalService) { }

  ngOnInit(): void {
    localStorage.setItem('numInscripciones', '10');
    this.obtenerListadoInscricion();
  }
  siguientePaso() {
    this.acPasos = Pasos.Inscripciones;
  }
  buscarCliente() {
    if (this.valorBusqueda === '') {
      return;
    }
    this.loader = false;
    this.UsuariosService.buscarCliente(this.valorBusqueda).subscribe((resp: ResponseGeneral) => {
      this.listaClientes = [...resp.data];
      this.loader = true;
    })
  }

  obtenerListadoInscricion(): void {
    this.InscripcionService.obtenerListadoInscripciones().subscribe((resp: ResponseGeneral) => {
      const listaInscripcion = resp.data;
      listaInscripcion.map((resp: Inscripcion) => {
        if (resp.status == 1) {
          resp.status = 'Activo';
        } else {
          resp.status = 'Inactivo';
        }
        return resp
      })
      this.listaInscripcion = [...listaInscripcion];
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    });
    // this.UsuariosService.obtenerListaClientes().subscribe((resp: ResponseGeneral) => {
    //   this.listaUsuarios = [...resp.data];
    // }, (err) => {
    //   Swal.fire('Error', err.error.msg, 'error');
    // });
    this.MensualidadesService.obtenerListadoMensualidades().subscribe((resp: ResponseGeneral) => {
      this.listaMensualidades = [...resp.data]
      this.loader = true;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  nuevaInscripcion(): void {
    this.inscripcionForm.reset();
    this.accionPagina = Acciones.Crear;
  }

  submitInscripcion(): void {
    this.loader = false;
    switch (this.accionPagina) {
      case Acciones.Actualizar:
        this.modificaInscripcion();
        break;

      default:
        this.crearInscripcion();
        break;
    }
  }

  modificaInscripcion(): void {
    this.loader = false;
    this.InscripcionService.actualizarInscripcion(this.inscripcionForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerListadoInscricion();
      Swal.fire('Correcto', resp.msg, 'success');
      this.accionPagina = Acciones.Listar;
      this.loader = true;
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  crearInscripcion(): void {
    this.InscripcionService.crearInscripcion(this.inscripcionForm.value).subscribe((resp: ResponseGeneral) => {
      this.obtenerListadoInscricion();
      this.accionPagina = Acciones.Listar;
      this.loader = true;
      Swal.fire('Correcto', resp.msg, 'success');
    }, (err) => {
      Swal.fire('Error', err.error.msg, 'error');
    })
  }

  actualizarInscripcion(data: Inscripcion): void {
    this.valorBusqueda = data.nombreCliente;
    this.buscarCliente();
    this.accionPagina = Acciones.Actualizar;
  }
  deleteInscripcion(data: Inscripcion): void {
    Swal.fire({
      title: `Estas Seguro ?`,
      text: `Se eliminara la mensualidad`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.InscripcionService.eliminarInscripcion(Number(data.id)).subscribe((resp: ResponseGeneral) => {
          this.obtenerListadoInscricion();
          Swal.fire('Correcto', resp.msg, 'success');
        });
      }
    });
  }
  inscripcion(typo: string, data: any): void {
    this.idUsuario = data.id;
    if (typo) {
      this.tipoInscripcion = 'reinscripcion';
    } else {
      this.tipoInscripcion = 'inscripcion';
    }
    this.abirModal(data)
  }

  abirModal(data: any) {
    this.ModalService.abirModalMensualidad();
  }

  public campoNoValido(campo: string): boolean {
    let ok = true;
    if (this.inscripcionForm.get(campo)?.errors) {
      this.mensajeError = `El campo ${campo} es requerido y debe de ser de almenos un caracter`
      return !ok;
    } else {
      this.mensajeError = '';
      return ok
    }
  }

  cancelarInscripcion(): void {
    this.accionPagina = Acciones.Listar;
    this.obtenerListadoInscricion();
    this.loader = false;
    this.listaClientes = [];
  }

  cambioValorMensualidad(valor: boolean) {
    this.modal = valor;
    const idMensualidad = this.ModalService.idMensualidad;
    if (this.modal) {
      switch (this.tipoInscripcion) {
        case 'inscripcion':
          const dataInscrip: Inscripcion = {
            id_mensualidad: idMensualidad,
            id_usuario: Number(this.idUsuario)
          }
          this.InscripcionService.crearInscripcion(dataInscrip).subscribe((resp: ResponseGeneral) => {
            if (resp.ok) {
              Swal.fire('Correcto', resp.msg, 'success');
              this.ModalService.cerrarModalMensualidad();
              this.buscarCliente();
            }
          }, (err) => {
            Swal.fire('Error', err.error.msg, 'error');
            this.ModalService.cerrarModalMensualidad();
          })
          break;

        default:
          const data = {
            id_usuario: this.idUsuario,
            id_mensualidad: idMensualidad
          }
          this.InscripcionService.editarInscripcion(data).subscribe((resp: ResponseGeneral) => {
            if (resp.ok) {
              Swal.fire('Correcto', resp.msg, 'success');
            }
          }, (err) => {
            Swal.fire('Error', err.error.msg, 'error');
          })
          this.ModalService.cerrarModalMensualidad();
          this.buscarCliente();
          break;
      }
    } else {
      this.ModalService.cerrarModalMensualidad();
    }

  }
}
