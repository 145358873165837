<div class="row" [hidden]="loader">
    <div class="col-12">
        <div class="text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
            <p class="mb-0">Espere</p>
        </div>
    </div>
</div>

<div *ngIf="accionPagina === 'Listar' && loader">
    <div class="col-12">
        <div class="d-flex justify-content-end mb-2">
        </div>
        <div class="card">
            <div class="d-flex justify-content-end mr-2 mt-2">
                <button type="button" class="btn btn-circle btn-md btn-primary"   data-placement="top" title="Crear nueva mensualidad"
                (click)="nuevaMensualidad();"><i class="fa fa-plus"></i></button>
            </div>
            <div class="card-body">
                <h4 class="card-title">Lista de Mensualidades</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Meses</th>
                                <th>Costo</th>
                                <th>Estatus</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lista of listaMensualidades">
                                <td>{{lista.nombre}}</td>
                                <td>{{lista.meses}}</td>
                                <td>{{lista.costo}}</td>
                                <td>{{lista.status}}</td>
                                <td class="text-nowrap">
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Edit"
                                        (click)="actualizarMensualidad(lista)"> <i
                                            class="fa fa-pencil text-inverse m-r-10"></i> </a>
                                    <a data-toggle="tooltip" class="pointer" data-original-title="Borrar"> <i
                                            class="fa fa-close text-danger" (click)="deleteMensualidad(lista)"></i> </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn btn-primary mr-2">siguiente</button>

                    <button class="btn btn-primary">anterior</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="accionPagina !== 'Listar'">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3>{{accionPagina === "Crear" ? 'Crear nueva mensualidad':'Editar mensualidad'}}</h3>
            </div>
            <div class="card-body">
                <form class="form-horizontal form-material" [formGroup]="mensualidadForm"
                    (submit)="(submitMensualidades())">
                    <div class="form-group">
                        <label>Descripción Mensualidad</label>
                        <input type="text" class="form-control" formControlName="nombre">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('nombre')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Meses</label>
                        <input type="email" class="form-control" formControlName="meses">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('meses')" class="text-danger"
                        [mensaje]="mensajeError"></span>

                    <div class="form-group">
                        <label>Costo</label>
                        <input type="email" class="form-control" formControlName="costo">
                    </div>
                    <span ErrorMsg [valido]="!campoNoValido('costo')" class="text-danger"
                        [mensaje]="mensajeError"></span>
                    <div class="row">
                        <button class="btn btn-secondary mr-2" [disabled]="!mensualidadForm.valid"> Guardar </button>
                        <button class="btn btn-danger" (click)="cancelarMensualidad();"> Cancelar </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>