import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap, map } from 'rxjs/operators';
import { ResponseGeneral } from '../interfaces/response.interfaces';
import { SetingsImg } from '../interfaces/setings.interfaces';
import { UsuariosService } from './usuarios.service';
import { Observable } from 'rxjs';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class SetingsImgService {

  constructor(private http: HttpClient, private UsuariosService: UsuariosService) { }


  async crearConfig(data: SetingsImg): Promise<ResponseGeneral> {
    const url = `${base_url}/configlocal/addConfLocal`;
    return await this.http.post(url, data).pipe(map((resp: ResponseGeneral) => {
      if (resp.ok) {
        localStorage.setItem('openConfig', '1');
        sessionStorage.setItem('negocioNombre', data.nombre);
      }
      return resp;
    })
    ).toPromise();
    // return this.http.post(url, data).pipe(
    //   tap((resp: ResponseGeneral) => {
    //     if (resp.ok) {
    //       localStorage.setItem('openConfig', '1');
    //     }
    //   })

  }

  async actualizarImagen(archivo: File, tipo: 'clientes' | 'negocio', id: number): Promise<ResponseGeneral> {
    const formData = new FormData();
    formData.append('imagen', archivo);
    const url = `${base_url}/upload/upload/${tipo}/${id}`;
    return await this.http.put(url, formData).pipe(map((resp: ResponseGeneral) => {
      if (resp.ok) {
        switch (tipo) {
          case 'negocio':
            sessionStorage.setItem('imgNegocio', resp.data)
            break;
          default:
            sessionStorage.setItem('img', resp.data)
            break;
        }
        this.UsuariosService.change$.next(true);
      }
      return resp
    })
    ).toPromise()
  }

  updateConfig(data: SetingsImg) {
    const url = `${base_url}/configlocal/updConfLocal`;
    return this.http.put(url, data)
  }

}
