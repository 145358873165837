import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _ocultarModal: boolean = true;
  private _idInstructor: number = 0;
  private _ocultarModalInscripcion: boolean = true;
  private _ocultarModalSetingIMG: boolean = true;
  private _idMensualidad: number = 0;

  get ocualtarModal() {
    return this._ocultarModal;
  }

  get idIstructor() {
    return this._idInstructor;
  }

  get ocultaMordalInscripcion() {
    return this._ocultarModalInscripcion;
  }

  get idMensualidad() {
    return this._idMensualidad;
  }

  get ocultarModalIMG() {
    return this._ocultarModalSetingIMG;
  }

  abirModal(): void {
    this._ocultarModal = false;
  }
  cerrarModal(): void {
    this._ocultarModal = true;
  }

  setIdInstructor(id: number): void {
    this._idInstructor = id;
  }
  abirModalMensualidad(): void {
    this._ocultarModalInscripcion = false;
  }
  cerrarModalMensualidad(): void {
    this._ocultarModalInscripcion = true;
  }

  abrirModalSetingIMG(): void {
    this._ocultarModalSetingIMG = false;
  }
  cerrarModalSetingIMG(): void {
    this._ocultarModalSetingIMG = true;
  }

  setIdMensualidad(id: number): void {
    this._idMensualidad = id;
  }

  constructor() { }
}
