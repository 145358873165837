import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthInterfaces } from '../interfaces/auth.interfaces';
import { tap, map, catchError } from 'rxjs/operators';
import { responsesAdmin } from '../interfaces/response.interfaces';
import { Observable, of } from 'rxjs';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) { }

  auth(data: AuthInterfaces) {
    const url = `${base_url}/auth/`;
    const datos = {
      email: data.correo,
      password: data.password
    }
    return this.http.post(url, datos).pipe(tap((resp: responsesAdmin) => {
      localStorage.setItem('token', resp.token)
      sessionStorage.setItem('id', resp.uid.toString());
      sessionStorage.setItem('nombre', resp.name.toString());
      sessionStorage.setItem('img', resp.img);
      sessionStorage.setItem('imgNegocio', resp.imgNegocio);
      sessionStorage.setItem('role', String(resp.role))
      localStorage.setItem('google_token', resp.google_token);
      localStorage.setItem('locationAutosur', resp.locationAutosur);
      localStorage.setItem('menu', JSON.stringify(resp.menu))
      localStorage.setItem('theme', '	./assets/css/colors/blue-dark.css');
    }))
  }

  validarToken(): Observable<boolean> {
    const token = localStorage.getItem('token') || '';
    const url = `${base_url}/auth/renew`;
    return this.http.get(url, {
      headers: {
        'x-token': token
      }
    }).pipe(
      tap((resp: any) => {
        localStorage.setItem('token', resp.token)
      }),
      map(resp => true),
      catchError(error => of(false))
    )
  }
  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('theme', '	./assets/css/colors/blue-dark.css');
  }

  refresTokenGoogle() {
    const url = `${base_url}/auth/renewGoogle`;
    return this.http.get(url).pipe(tap((resp: any) => {
      localStorage.setItem('google_token', resp.data.tokenGoogle)
    }));
  }
}
