<div class="fondoModal animated fadeIn fast" [class.oculto]="ModalService.ocultarModalIMG">
    <div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1"
        style="display: block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel1"> <i class="fa fa-cog fa-1x"></i> Configuración</h4>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-material" [formGroup]="congForm" (submit)="submitConfig()">
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <label class="form-label">
                                    Nombre del negocio
                                </label>
                                <input class="form-control" type="text" formControlName="nombre">
                            </div>
                            <span ErrorMsg [valido]="!campoNoValido('nombre')" class="text-danger"
                                [mensaje]="mensajeError"></span>
                        </div>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <label class="form-label">
                                    Dirección
                                </label>
                                <input class="form-control" type="text" formControlName="direccion">
                            </div>
                            <span ErrorMsg [valido]="!campoNoValido('direccion')" class="text-danger"
                                [mensaje]="mensajeError"></span>
                        </div>
                        <input type="file" (change)="cambiarImagen($event.target.files[0])">
                        <div class="text-center">
                            <!-- <img *ngIf="!imgTemp" [src]="usuario.imagenUrl" class="img-avatar"> -->
                            <img *ngIf="imgTemp" [src]="imgTemp" class="img-avatar">
                        </div>
                        <br>
                        <button type="submit" class="btn btn-success btn-block waves-effect waves-light m-r-10"
                            [disabled]="!congForm.valid">
                            <i class="fa fa-save"></i>
                            Guardar Cambios
                        </button>
                    </form>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-default" data-dismiss="modal" (click)="cerrarModal()">Close</button> -->
                    <!-- <button type="button" class="btn btn-primary" (click)="aceptar();">Aceptar</button> -->
                </div>
            </div>
        </div>
    </div>
</div>